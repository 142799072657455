import { Controller } from "stimulus";
import Dropzone from "../../helpers/dropzone_helper";

export default class extends Controller {
  static targets = ["dropzone", "form", "input"];

  initialize() {
    new Dropzone(this.dropzoneTarget, this.dropzoneOptions);
  }

  _onHover() {
    // Apply hover styles
    this.dropzoneTarget.dataset.placeholder = "Drop images to upload...";
    this.dropzoneTarget.setAttribute("data-permanent-placeholder", "true");
  }

  _dragLeave() {
    // Remove hover styles
    this.dropzoneTarget.dataset.placeholder = "Type '/' for commands...";
    this.dropzoneTarget.removeAttribute("data-permanent-placeholder");
  }

  _onDrop() {
    // Apply drop styles
    this.dropzoneTarget.dataset.placeholder = "Uploading...";
    this.dropzoneTarget.setAttribute("data-permanent-placeholder", "true");

    // Prevent editing while uploading
    this.dropzoneTarget.setAttribute("contenteditable", "false");
  }

  _allowUpload() {
    // Do not allow upload if the content element has text
    const imageInputText = this.dropzoneTarget.textContent.trim();
    return imageInputText.length === 0;
  }

  get dropzoneOptions() {
    return {
      input: this.inputTarget,
      form: this.formTarget,
      onHover: this._onHover.bind(this),
      onDrop: this._onDrop.bind(this),
      dragLeave: this._dragLeave.bind(this),
      allowUpload: this._allowUpload.bind(this),
    };
  }
}
