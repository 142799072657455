import { Controller } from "stimulus";

export default class extends Controller {
  static values = { path: String };

  async connect() {
    const closeModal = new CustomEvent("modal:close");
    document.dispatchEvent(closeModal);

    await new Promise((resolve) => setTimeout(resolve, 100));
    Turbo.visit(this.pathValue);
    this.element.remove();
  }
}
