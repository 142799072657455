// Used in app/views/projects/reports/pdf_downloads/_advanced.html.erb
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "benchmarkMatrix",
    "excludeBenchmarks",
    "excludeRatings",
    "auditScoring",
  ];

  initialize() {
    this.benchmarkMatrixLabel = this.benchmarkMatrixTarget.closest("label");
    this.auditScoringLabel = this.auditScoringTarget.closest("label");
  }

  connect() {
    this.excludeBenchmarksTarget.addEventListener(
      "change",
      this._toggleBenchmarkMatrix.bind(this)
    );

    this.excludeRatingsTarget.addEventListener(
      "change",
      this._toggleGradingSystem.bind(this)
    );
  }

  disconnect() {
    this.excludeBenchmarksTarget.removeEventListener(
      "change",
      this._toggleBenchmarkMatrix.bind(this)
    );

    this.excludeRatingsTarget.removeEventListener(
      "change",
      this._toggleGradingSystem.bind(this)
    );
  }

  _toggleBenchmarkMatrix() {
    // Case 1: exclude benchmarks IS checked
    if (this.excludeBenchmarksTarget.checked) {
      this.benchmarkMatrixTarget.checked = false;
      this.benchmarkMatrixTarget.disabled = true;
      this.benchmarkMatrixLabel.classList.remove("cursor-pointer");
      return;
    }

    // Case 2: exclude benchmarks is NOT checked
    this.benchmarkMatrixTarget.disabled = false;
    this.benchmarkMatrixLabel.classList.add("cursor-pointer");
  }

  _toggleGradingSystem() {
    // Case 1: exclude audit scoring IS checked
    if (this.excludeRatingsTarget.checked) {
      this.auditScoringTarget.checked = false;
      this.auditScoringTarget.disabled = true;
      this.auditScoringLabel.classList.remove("cursor-pointer");
      return;
    }

    // Case 2: exclude audit scoring is NOT checked
    this.auditScoringTarget.disabled = false;
    this.auditScoringLabel.classList.add("cursor-pointer");
  }
}
