class ClickableLinksController {
  constructor(host) {
    this.host = host;
    host.addController(this);
  }

  hostConnected() {
    this._toggleEventListeners("addEventListener");

    this.host.addEventListener("contentElementInput:linksUpdated", () =>
      this._toggleEventListeners("addEventListener")
    );
  }

  hostDisconnected() {
    this._toggleEventListeners("removeEventListener");
  }

  _toggleEventListeners(action) {
    this.links.forEach((link) => {
      link[action]("click", this._handleClick.bind(this, link));
      link[action]("mouseover", this._handleMouseover.bind(this, link));
      link[action]("mouseleave", this._handleMouseleave.bind(this, link));
    });
  }

  _handleClick(link, { metaKey }) {
    if (link.href && metaKey) window.open(link.href);
  }

  _handleMouseover(link, { metaKey }) {
    if (metaKey) link.classList.add("cursor-pointer");

    document.addEventListener("keydown", this._handleKeydown.bind(this, link));
    document.addEventListener("keyup", this._handleKeyup.bind(this, link));
  }

  _handleMouseleave(link) {
    link.classList.remove("cursor-pointer");

    document.removeEventListener(
      "keydown",
      this._handleKeydown.bind(this, link)
    );
    document.removeEventListener("keyup", this._handleKeyup.bind(this, link));
  }

  _handleKeydown(link, { metaKey }) {
    if (metaKey) link.classList.add("cursor-pointer");
  }

  _handleKeyup(link, { metaKey }) {
    if (!metaKey) link.classList.remove("cursor-pointer");
  }

  get links() {
    return this.host.querySelectorAll("a");
  }
}

export default ClickableLinksController;
