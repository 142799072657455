import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "counter"];
  static values = { maxLength: Number };

  connect() {
    this.updateCounter();
    this.inputTarget.addEventListener("input", this.updateCounter.bind(this));
  }

  disconnect() {
    this.inputTarget.removeEventListener(
      "input",
      this.updateCounter.bind(this)
    );
  }

  updateCounter() {
    const input = this.inputTarget;
    const counter = this.counterTarget;
    const maxLength = this.maxLengthValue;
    let titleLength = 0;

    if (input.tagName === "INPUT") {
      // countable field is a input
      titleLength += input.value.length;
    } else {
      // countable field is an editable html element
      titleLength += input.textContent.length;
    }

    const remainingChars = maxLength - titleLength;
    const noun = remainingChars === 1 ? "character" : "characters";
    counter.textContent = `${remainingChars} ${noun} remaining`;

    if (remainingChars < 0) {
      counter.classList.add("text-red-400");
    } else {
      counter.classList.remove("text-red-400");
    }
  }
}
