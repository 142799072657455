import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["trigger"];

  displayDropdowns() {
    if (!this.dropdownsHidden) return;

    this.triggerTargets.forEach((trigger) => {
      trigger.classList.remove("opacity-0");
    });
  }

  hideDropdowns() {
    if (this.dropdownsHidden) return;

    this.triggerTargets.forEach((trigger) => {
      trigger.classList.add("opacity-0");
    });
  }

  get dropdownsHidden() {
    return this.triggerTargets.every((trigger) => {
      return trigger.classList.contains("opacity-0");
    });
  }
}
