import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "allBlueprintsInput",
    "blueprint",
    "blueprintInput",
    "list",
    "template",
    "turboFrame",
  ];

  static values = { filterPath: String };

  initialize() {
    this.frameLoadHandler = this._handleFrameLoad.bind(this);

    this.activeClasses = [
      "!bg-emerald-50",
      "!hover:bg-emerald-50",
      "!focus:bg-emerald-50",
    ];
  }

  connect() {
    this.turboFrameTarget.addEventListener(
      "turbo:frame-render",
      this.frameLoadHandler
    );
  }

  disconnect() {
    this.turboFrameTarget.removeEventListener(
      "turbo:frame-render",
      this.frameLoadHandler
    );
  }

  filter() {
    const value = this.allBlueprintsInputTarget.dataset.value;
    const param = `all_blueprints_filter=${value}`;
    this.searchController.search(param);
  }

  search() {
    this.filter();
  }

  select({ currentTarget }) {
    if (this._isSelected(currentTarget)) {
      this._removeFromList(currentTarget);
      this._clearSelection(currentTarget);
    } else {
      this._addToList(currentTarget);
      this._setSelection(currentTarget);
    }

    this._setInputValue();
  }

  destroy({ currentTarget }) {
    const listItem = currentTarget.parentElement;
    listItem.remove();

    const blueprintId = listItem.dataset.blueprint;
    const selector = `[data-blueprint="${blueprintId}"]`;
    const blueprint = this.turboFrameTarget.querySelector(selector);
    if (blueprint) this._clearSelection(blueprint);

    this._setInputValue();
  }

  _setInputValue() {
    const value = this.selectedBlueprintIds;
    this.blueprintInputTarget.value = JSON.stringify(value);
  }

  _addToList(item) {
    // Get key data from item
    const id = item.dataset.blueprint;
    const html = item.querySelector(`[data-blueprint='${id}']`).innerHTML;
    const blueprintId = item.dataset.blueprint;

    // Deep clone template
    const templateContent = this.templateTarget.content.firstElementChild;
    const listItem = templateContent.cloneNode(true);
    listItem.dataset.blueprint = blueprintId;
    listItem.querySelector("p").innerHTML = html;

    // Append to list
    this.listTarget.appendChild(listItem);
  }

  _removeFromList(item) {
    const identifier = `[data-blueprint="${item.dataset.blueprint}"]`;
    const listItem = this.listTarget.querySelector(identifier);
    listItem.remove();
  }

  _setSelection(item) {
    this._setLineClamp("remove", item);
    item.classList.add(...this.activeClasses);
  }

  _clearSelection(item) {
    if (!item) return;

    item.classList.remove(...this.activeClasses);
    this._setLineClamp("add", item);
  }

  _setLineClamp(action, element) {
    if (!element) return;

    const lineClampElement = element.querySelector("[lineclampable]");
    if (!lineClampElement) return;

    lineClampElement.classList[action]("line-clamp-3");
  }

  _handleFrameLoad() {
    const listItems = [...this.listTarget.querySelectorAll(":scope > *")];

    listItems.map((item) => {
      const blueprintId = item.dataset.blueprint;
      const selector = `[data-blueprint="${blueprintId}"]`;
      const blueprint = this.turboFrameTarget.querySelector(selector);
      if (blueprint) this._setSelection(blueprint);
    });
  }

  _isSelected(item) {
    return item.classList.contains(this.activeClasses[0]);
  }

  get selectedBlueprintIds() {
    return [...this.listTarget.querySelectorAll(":scope > *")].map((item) => {
      return item.dataset.blueprint;
    });
  }

  get searchController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("search_wrapper"),
      "shared--search--component"
    );
  }
}
