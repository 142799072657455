import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item", "input"];

  connect() {
    if (this.hasAsyncTurboFrame) {
      this._waitForFrameLoad();
    } else {
      this._addKeydownListeners();
    }

    if (!this.hasTurboFrame) return;

    this.turboFrame.addEventListener("turbo:frame-render", () => {
      this._addKeydownListeners();
    });
  }

  handleKeydown(event) {
    const { key, target, shiftKey } = event;

    // Navigate to next item
    if (key === "ArrowDown" || (key === "Tab" && !shiftKey)) {
      this._next(event, target);
      return;
    }

    // Navigate to previous item
    if (key === "ArrowUp" || (key === "Tab" && shiftKey)) {
      this._previous(event, target);
      return;
    }

    // Bail here if keydown was on the input target
    if (this.hasInputTarget && this.inputTarget === target) return;

    // Select item
    if (key === "Enter") {
      event.preventDefault();
      this._select(target);
    }
  }

  _waitForFrameLoad() {
    this.turboFrame.addEventListener("turbo:frame-render", () => {
      this._addKeydownListeners();
    });
  }

  _addKeydownListeners() {
    this.items.forEach((item) => {
      item.tabIndex = 0;
      item.addEventListener("keydown", this.handleKeydown.bind(this));
    });
  }

  _select(item) {
    // You can't select an input, you can only navigate through it
    if (this.hasInputTarget && item === this.inputTarget) return;

    // If the item's a link, the browser will automatically follow it
    if (item.tagName === "A") return item.click();

    // If there's a form inside the item, submit it
    const form = item.querySelector("form");
    if (form) return form.requestSubmit();

    // If there's a link inside the item, click it
    const link = item.querySelector("a");
    if (link) return link.click();

    // Otherwise just click the item itself
    item.click();
  }

  _next(event, item) {
    // Get next item
    const index = this._getIndexOf(item);
    const nextIndex = index === this.lastIndex ? this.firstIndex : index + 1;
    const nextItem = this.items[nextIndex];

    // Bail if next item doesn't exist
    if (!nextItem || nextItem === item) return;

    // Focus on next item
    event.preventDefault();
    nextItem.focus();
  }

  _previous(event, item) {
    // Get previous item
    const index = this._getIndexOf(item);
    const prevIndex = index === this.firstIndex ? this.lastIndex : index - 1;
    const prevItem = this.items[prevIndex];

    // Bail if previous item doesn't exist
    if (!prevItem || prevItem === item) return;

    // Focus on previous item
    event.preventDefault();
    prevItem.focus();
  }

  _getIndexOf(item) {
    return this.items.findIndex((target) => target === item);
  }

  get turboFrame() {
    return this.element.querySelector("turbo-frame");
  }

  get hasTurboFrame() {
    return !!this.turboFrame;
  }

  get hasAsyncTurboFrame() {
    return this.turboFrame && this.turboFrame.getAttribute("src");
  }

  get items() {
    return this.hasItemTarget
      ? this.targets.findAll("item")
      : [...this.element.querySelectorAll(".dropdown-link")];
  }

  get firstIndex() {
    return 0;
  }

  get lastIndex() {
    return this.items.length - 1;
  }
}
