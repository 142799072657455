import { Controller } from "stimulus";

export default class extends Controller {
  static values = { animation: String, hide: Array };

  animate() {
    // Show the animation
    console.log(this.animation);
    this.animation.classList.remove("hidden");

    // Hide other elements
    this.elementsToHide.forEach((element) => {
      console.log(element);

      element.classList.add("hidden");
    });
  }

  get animation() {
    return document.getElementById(this.animationValue);
  }

  get elementsToHide() {
    return this.hideValue.map((id) => document.getElementById(id));
  }
}
