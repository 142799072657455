import { Controller } from "stimulus";

export default class extends Controller {
  static values = { keys: Array, container: String };

  initialize() {
    this.handleHotkey = this._handleHotkey.bind(this);
  }

  connect() {
    this.keys = this._parseKeys();
    this.container.addEventListener("keydown", this.handleHotkey);
  }

  disconnect() {
    this.container.removeEventListener("keydown", this.handleHotkey);
  }

  _handleHotkey({ metaKey, ctrlKey, key }) {
    this.keys.forEach((keyData) => {
      // Check for meta
      if (keyData.requiresMeta && !metaKey) return;

      // Check for ctrl
      if (keyData.requiresCtrl && !ctrlKey) return;

      // Check for key
      if (key !== keyData.key) return;

      // Click this.element if no specific element was provided
      if (!keyData.toClick) return this.element.click();

      // Else click the provided element
      const element = document.getElementById(keyData.toClick);
      element && element.click();
    });
  }

  _parseKeys() {
    return this.keysValue.map((entry) => this._parseItem(entry));
  }

  _parseItem(entry) {
    let [keys, toClick] = entry.split("→");
    keys = keys.split("+");

    return {
      requiresCtrl: keys.includes("ctrl"),
      requiresMeta: keys.includes("meta"),
      key: keys[keys.length - 1],
      toClick: toClick,
    };
  }

  get container() {
    if (!this.hasContainerValue) return this.element;

    return document.getElementById(this.containerValue);
  }
}
