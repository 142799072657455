import { Controller } from "stimulus";

export default class extends Controller {
  submit() {
    this.element.requestSubmit();
  }

  submitWith({ params }) {
    Object.entries(params).forEach(([id, value]) => {
      const input = document.getElementById(id);
      input.value = value;
    });

    this.submit();
  }
}
