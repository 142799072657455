import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loadSpinner", "textContent"];

  initialize() {
    this.revealSpinner = this.showLoadSpinner.bind(this);
  }

  connect() {
    this.element.addEventListener("click", this.revealSpinner);
    this.form?.addEventListener("requestSubmit", this.revealSpinner);
  }

  disconnect() {
    this.element.removeEventListener("click", this.revealSpinner);
    this.form?.removeEventListener("requestSubmit", this.revealSpinner);
  }

  showLoadSpinner() {
    if (this.formIsInvalid) return;

    this.loadSpinnerTarget.classList.remove("hidden");
    this.textContentTarget.classList.add("opacity-0");
    setTimeout(() => (this.element.disabled = true), 1);
  }

  get form() {
    return (this._form ||= this.element.closest("form"));
  }

  get formIsInvalid() {
    return this.form && !this.form.checkValidity();
  }
}
