import { Controller } from "@hotwired/stimulus";
import Animation from "../../../javascript/helpers/animation_helper";

export default class extends Controller {
  static targets = ["background", "container", "modal"];

  initialize() {
    this.animation = new Animation();

    this.restrictSize = [
      "md:h-fit",
      "md:max-h-[96vh]",
      "md:mt-12",
      "md:rounded-xl",
      "md:w-[42rem]",
      "sm:scale-95",
      "sm:translate-y-0",
      "translate-y-4",
    ];
  }

  connect() {
    this.modalTarget.addEventListener("turbo:frame-load", ({ target }) => {
      if (target !== this.modalTarget) return;
      if (this.isVisible) return;

      this.show();
    });

    document.addEventListener("modal:close", this.hide.bind(this));
  }

  disconnect() {
    document.removeEventListener("modal:close", this.hide.bind(this));
  }

  hideOnEsc({ key }) {
    if (key !== "Escape") return;

    this.hide();
    document.removeEventListener("keydown", this.hideOnEsc.bind(this));
  }

  hideOnFormSuccess({ detail: { success } }) {
    if (success) this.hide();
  }

  async show() {
    // Apply size classes
    if (this.fullScreen) {
      this.modalTarget.classList.remove(...this.restrictSize);
    } else {
      this.modalTarget.classList.add(...this.restrictSize);
    }

    this.containerTarget.classList.remove("hidden");

    await Promise.all([
      this.animation.enter(this.modalTarget, false),
      this.animation.enter(this.backgroundTarget, false),
    ]);

    const focusOn = this.focusableInput || this.firstInput;
    if (focusOn) focusOn.focus();

    document.addEventListener("keydown", this.hideOnEsc.bind(this));
    document.body.classList.add("overflow-y-hidden");
  }

  async hide() {
    await Promise.all([
      this.animation.asyncExit(this.modalTarget, false),
      this.animation.asyncExit(this.backgroundTarget, false),
    ]);

    this.containerTarget.classList.add("hidden");
    this.modalTarget.innerHTML = "";
    document.body.classList.remove("overflow-y-hidden");
  }

  get focusableInput() {
    return this.modalTarget.querySelector("[data-focus='true']");
  }

  get firstInput() {
    return this.modalTarget.querySelector(
      "input:not([type='hidden']):not([type='checkbox']):not([type='file']), textarea:not([type='hidden'], [contenteditable='true'])"
    );
  }

  get fullScreen() {
    // We indicate fullscreen by appending a <template data-fullscreen="true"> to the modal
    const selector = "template[data-fullscreen='true']";
    const template = this.modalTarget.querySelector(selector);
    if (!template) return false;

    template.remove();
    return true;
  }

  get isVisible() {
    return !this.containerTarget.classList.contains("hidden");
  }
}
