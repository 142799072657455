// Controller for selecting/unselecting records and updating their counters
import { Controller } from "stimulus";
import consumer from "../../channels/consumer";

export default class extends Controller {
  connect() {
    this.channel = this._createChannel();
  }

  disconnect() {
    if (this.channel) {
      this.channel.unsubscribe();
      this.channel = null;
    }
  }

  _createChannel() {
    return consumer.subscriptions.create(
      {
        channel: "SelectorChannel",
        page: window.location.pathname,
      },
      {
        received: (data) => this._processData(data),
      }
    );
  }

  _processData({ action, records }) {
    if (action === "selection") return this._updateSelection(records);
    if (action === "counts") return this._updateCounts(records);
  }

  _updateSelection({ selected, unselected }) {
    selected.forEach(async (id) => {
      const element = document.getElementById(id);
      if (element) this._toggleClasses(element, "add");
    });

    unselected.forEach(async (id) => {
      const element = document.getElementById(id);
      if (element) this._toggleClasses(element, "remove");
    });
  }

  _toggleClasses(element, action) {
    element.querySelectorAll("[data-selectable]").forEach((selectable) => {
      const classes = selectable.dataset.selectableClasses;
      selectable.classList[action](...classes.split(" "));
    });
  }

  _updateCounts(records) {
    Object.entries(records).forEach(async ([id, count]) => {
      const element = document.getElementById(id);
      if (element) element.textContent = count;
    });
  }
}
