// Allows for sliding through carousel images (e.g. uploads#show) via left and right keys
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["next", "previous"];

  initialize() {
    this.navigate = this._navigate.bind(this);
  }

  connect() {
    if (!this.allowSlide) return;

    document.addEventListener("keydown", this.navigate);
  }

  disconnect() {
    document.removeEventListener("keydown", this.navigate);
  }

  _navigate({ key }) {
    const isArrowLeft = key === "ArrowLeft";
    const isArrowRight = key === "ArrowRight";

    if (isArrowLeft) return this.previousTarget.click();
    if (isArrowRight) return this.nextTarget.click();
  }

  get allowSlide() {
    return this.hasNextTarget && this.hasPreviousTarget;
  }
}
