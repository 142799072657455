import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "input"];

  hide() {
    this.inputTarget.value = "";
    this.containerTarget.classList.add("hidden");
  }

  show() {
    this.containerTarget.classList.remove("hidden");
  }
}
