import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["radio", "dropdown"];

  initialize() {
    this.radioTarget.value = "en";
  }

  connect() {
    this.element.addEventListener("submit", this._setLanguage.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("submit", this._setLanguage.bind(this));
  }

  _setLanguage() {
    if (!this.radioTarget.checked) return;

    this.radioTarget.value = this.dropdownTarget.value;
  }
}
