import { Controller } from "stimulus";

export default class extends Controller {
  static values = { message: String };

  confirmOnClick(event) {
    if (!confirm(this.messageValue)) {
      event.preventDefault();
    }
  }
}
