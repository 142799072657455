import { Controller } from "stimulus";
import { getInput, moveCursorToEnd } from "../helpers/text_helper";

export default class extends Controller {
  toggle(event) {
    const selector = event.params.selector;
    const targets = document.querySelectorAll(selector);

    targets.forEach((target) => {
      this.toggleClasses(target);
      this.handleFocus(target);
    });
  }

  toggleOnEsc(event) {
    if (event.key === "Escape") this.toggle(event);
  }

  toggleClasses(target) {
    const classes = this.getClasses(target.dataset.classes);
    classes.forEach((c) => target.classList.toggle(c));
  }

  getClasses(classes) {
    if (!classes) return ["hidden"];

    return classes.split(",");
  }

  handleFocus(target) {
    if (!target.dataset.focusOnToggle || target.classList.contains("hidden"))
      return;

    const input = getInput(target);
    if (!input) return;

    const tagName = input.tagName;

    if (tagName === "TEXTAREA" || tagName === "INPUT") {
      input.focus({ preventScroll: true });
    } else {
      moveCursorToEnd(input);
    }
  }
}
