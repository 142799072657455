import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["contentElement", "contentElementInput"];

  toggleSelection({ currentTarget }) {
    if (currentTarget.classList.contains("!bg-emerald-50")) {
      // Remove the green background on the selected element
      currentTarget.classList.remove("!bg-emerald-50");

      // Set the hidden input to null
      this.handleSubmit(null);
    } else {
      // Remove the green background on all elements
      this.removeBackgroundOnAllElements();

      // Set the green background for selected element
      currentTarget.classList.add("!bg-emerald-50");

      // Get the content element id from the selected element and set it to the hidden input
      let contentElementId = currentTarget.dataset.contentElementId;
      this.handleSubmit(contentElementId);
    }
  }

  handleSubmit(value) {
    this.contentElementInputTarget.value = value;
  }

  removeBackgroundOnAllElements() {
    this.contentElementTargets.forEach((element) => {
      if (element.classList.contains("!bg-emerald-50")) {
        element.classList.remove("!bg-emerald-50");
      }
    });
  }
}
