import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];
  static classes = ["active", "inactive"];

  toggle({ currentTarget, params }) {
    this._makeTabsInactive();
    this._hideContentContainers();

    // apply active classes to tab
    currentTarget.classList.add(this.activeClass);

    // display corresponding content
    const id = `#${params.target}`;
    const contentContainer = this.element.querySelector(id);
    contentContainer.classList.remove("hidden");
  }

  _makeTabsInactive() {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove(this.activeClass);
      tab.classList.add(this.inactiveClass);
    });
  }

  _hideContentContainers() {
    this.contentTargets.forEach((target) => target.classList.add("hidden"));
  }
}
