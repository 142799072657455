import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    copyable: String,
    copyableElement: String,
    successText: String,
  };

  initialize() {
    this.copy = this._copyToClipboard.bind(this);
  }

  connect() {
    this.element.addEventListener("click", this.copy);
  }

  disconnect() {
    this.element.removeEventListener("click", this.copy);
  }

  _copyToClipboard() {
    this._addClasses();
    navigator.clipboard.writeText(this.textToCopy);
    fetch(`/notifications?text=${this.successTextValue}`);
  }

  _addClasses() {
    this.element.classList.add("transition-color", "duration-500");
  }

  get textToCopy() {
    if (this.hasCopyableValue) return this.copyableValue;

    const copyableElement = document.getElementById(this.copyableElementValue);
    return copyableElement.innerText;
  }
}
