import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "promptId",
    "submitButton",
    "input",
    "form",
    "promptTrigger",
  ];

  connect() {
    this.formTarget.addEventListener("submit", (event) => {
      setTimeout(() => {
        this.formTarget.reset();
      }, 0);
    });
  }

  setPromptId({ params }) {
    this.promptIdTarget.value = params.promptId;
  }

  setButtonState() {
    if (this.hasMessageContent) {
      this.submitButtonTarget.disabled = false;
      this.submitButtonTarget.classList.add("!text-green-accent");
    } else {
      this.submitButtonTarget.disabled = true;
      this.submitButtonTarget.classList.remove("!text-green-accent");
    }
  }

  handleEnter(event) {
    if (event.key !== "Enter") return;

    // Allow default behavior if shift key is pressed
    if (event.shiftKey) return;

    // Otherwise submit the form
    event.preventDefault();
    this.formTarget.requestSubmit();
  }

  // Open prompt dropdown on slash key
  handleSlash(event) {
    if (event.key !== "/") return;
    if (this.hasMessageContent) return;

    event.preventDefault();
    this.promptTriggerTarget.click();
  }

  get hasMessageContent() {
    return this.inputTarget.value.trim().length > 0;
  }
}
