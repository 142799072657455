import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["parent", "child"];

  toggleParent() {
    if (this.parentTarget.checked) return this._selectAllCheckboxes();

    this._unselectAllCheckboxes();
  }

  toggleChild() {
    if (this.parentTarget.checked && this._noCheckboxesSelected) {
      this.parentTarget.checked = false;
      return;
    }

    if (!this.parentTarget.checked && this._anyCheckboxesSelected) {
      this.parentTarget.checked = true;
    }
  }

  _selectAllCheckboxes() {
    this.childTargets.forEach((checkbox) => {
      checkbox.checked = true;
      checkbox.dispatchEvent(new Event("change"));
    });

    this.parentTarget.checked = true;
  }

  _unselectAllCheckboxes() {
    this.childTargets.forEach((checkbox) => {
      checkbox.checked = false;
      checkbox.dispatchEvent(new Event("change"));
    });

    this.parentTarget.checked = false;
  }

  get _allCheckboxesSelected() {
    return this.childTargets.every((checkbox) => checkbox.checked);
  }

  get _anyCheckboxesSelected() {
    return this.childTargets.some((checkbox) => checkbox.checked);
  }

  get _noCheckboxesSelected() {
    return this.childTargets.every((checkbox) => !checkbox.checked);
  }
}
