import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["parentList"];

  showSectionsList({ target }) {
    const selectedParent = target.value;
    this.parentListTargets.forEach((parentList) =>
      this._toggleListDisplay(parentList, selectedParent)
    );
  }

  _toggleListDisplay(parentList, selectedParent) {
    // Hide list if corresponding parent is not selected
    if (parentList.dataset.parent !== selectedParent) {
      parentList.classList.add("hidden");
      return;
    }

    // Show list if corresponding parent is selected
    parentList.classList.remove("hidden");
    const firstRadio = parentList.querySelector("input[type=radio]");
    firstRadio.checked = true;
  }
}
