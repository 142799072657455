import { Controller } from "stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  initialize() {
    this.activeQuestion = null;
    this.main = document.querySelector("main");
    this.clear = this._clear.bind(this);
    this.activeQuestionFromHash = this._activateQuestionFromHash.bind(this);
  }

  connect() {
    this.activeQuestionFromHash();
    window.addEventListener("hashchange", this.activeQuestionFromHash);
  }

  disconnect() {
    this._deactivateQuestion();
    this.main.removeEventListener("click", this.clear);
    window.removeEventListener("hashchange", this.activeQuestionFromHash);
  }

  setActive(event) {
    // Don't allow click event to bubble outside of question cards
    const question = event.currentTarget;

    // Bail here if element is already active
    if (question === this.activeQuestion) return;

    this._activateQuestion(question);
  }

  _setPresence() {
    const url = this._buildUrl();
    if (!url) return;

    const request = new FetchRequest("POST", url);
    request.perform();
  }

  _unsetPresence() {
    const url = this._buildUrl();
    if (!url) return;

    const request = new FetchRequest("DELETE", url);
    request.perform();
  }

  _activateQuestionFromHash() {
    const hash = window.location.hash;
    const hashElement = hash ? document.querySelector(hash) : null;
    const question = this._hashElementToQuestion(hashElement);
    question && this._activateQuestion(question);
  }

  _activateQuestion(question) {
    // Deactivate the previous active question
    this._deactivateQuestion(false);

    // Set the new active question
    this.activeQuestion = question;

    // Set active UI and update presence container
    this.activeQuestion.classList.add("!border-slate-800");
    this._setPresence();

    // Remove presence on click
    this._setClearListener();
  }

  _deactivateQuestion(shouldUnsetPresence = true) {
    const question = this.activeQuestion;
    if (!question) return;

    // Set inactive UI and update presence container
    question.classList.remove("!border-slate-800");
    shouldUnsetPresence && this._unsetPresence();

    this.activeQuestion = null;
  }

  _setClearListener() {
    setTimeout(() => {
      this.main.addEventListener("click", this.clear, { once: true });
    }, 0);
  }

  _clear(event) {
    if (!event) return this._deactivateQuestion();

    const target = event.target;
    if (!target) return this._deactivateQuestion();

    // Reset clear listener if user clicked on a question card
    if (target.getAttribute("workshop_question_card"))
      return this._setClearListener();

    // Reset clear listener if user clicked inside a question card
    if (target.closest("[workshop_question_card]"))
      return this._setClearListener();

    this._deactivateQuestion();
  }

  _hashElementToQuestion(element) {
    if (!element) return;
    if (!element.id.includes("question")) return;

    return element.querySelector("[workshop_question_card]");
  }

  _buildUrl() {
    const question = this.activeQuestion;
    if (!question) return;

    const id = question.dataset.questionId;
    if (!id) return;

    return `/workshop/phase_questions/${id}/presence`;
  }
}
