import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    selfDestruct: Boolean,
    target: String,
    waitTime: { type: Number, default: 1000 },
  };

  initialize() {
    this.highlightClasses = ["!bg-fuchsia-50", "!border-fuchsia-100"];
    this.transitionClasses = ["duration-700", "transition"];
    this.removeHighlight = this.removeHighlight.bind(this);
  }

  async connect() {
    this.target = this.fetchTarget();
    this.addHighlight();
    this.disconnect();
  }

  disconnect() {
    if (this.selfDestructValue) this.element.remove();
  }

  addHighlight() {
    // Add necessary classes
    this.target.classList.add(...this.transitionClasses);
    this.target.classList.add(...this.highlightClasses);

    // Sleep for a moment, then remove highlight
    setTimeout(this.removeHighlight, this.waitTimeValue);
  }

  removeHighlight() {
    this.target.classList.remove(...this.highlightClasses);
  }

  fetchTarget() {
    if (!this.hasTargetValue) return this.element;

    return document.getElementById(this.targetValue);
  }
}
