class NavigationController {
  constructor(host, options) {
    this.host = host;
    this.navigateOnEnter = options["navigateOnEnter"];
    this.onNavigate = options["onNavigate"];
    host.addController(this);
    this.keydownHandler = this._handleKeydown.bind(this);
  }

  hostConnected() {
    this.host.addEventListener("keydown", this.keydownHandler);
  }

  hostDisconnected() {
    this.host.removeEventListener("keydown", this.keydownHandler);
  }

  _handleKeydown(event) {
    if (!event.target === this.host) return;
    const { key, altKey, metaKey, shiftKey, ctrlKey } = event;

    if (key === "ArrowDown" && altKey && !(metaKey || ctrlKey))
      return this._navigateToEndOfNextElement(event);

    if (key === "ArrowUp" && altKey && !(metaKey || ctrlKey))
      return this._navigateToStartOfPreviousElement(event);

    if (key === "ArrowDown" && metaKey && !altKey && !shiftKey) {
      return this._navigateToEndOfLastElement(event);
    }

    if (key === "ArrowUp" && metaKey && !altKey && !shiftKey) {
      return this._navigateToStartOfFirstElement(event);
    }

    if ((key === "ArrowLeft" || key === "ArrowUp") && !(metaKey || ctrlKey))
      return this._navigateToPreviousElement(event);

    if ((key === "ArrowRight" || key === "ArrowDown") && !(metaKey || ctrlKey))
      return this._navigateToNextElement(event);

    if (key === "Enter" && this.navigateOnEnter) {
      return this._navigateTo(this.nextElement, event);
    }
  }

  // "option + down"
  _navigateToEndOfNextElement(event) {
    if (!this.nextElement) return;

    if (this.host.cursorAtEnd)
      return this._navigateTo(this.nextElement, event, true);

    this.host.moveCursorToEnd();
  }

  // "option + up"
  _navigateToStartOfPreviousElement(event) {
    if (!this.previousElement) return;

    if (this.host.cursorAtStart)
      return this._navigateTo(this.previousElement, event);

    this.host.focus();
  }

  // "cmd + up"
  _navigateToStartOfFirstElement(event) {
    this._navigateTo(this.firstElement, event);
  }

  // "cmd + down"
  _navigateToEndOfLastElement(event) {
    this._navigateTo(this.lastElement, event, true);
    this.lastElement.scrollIntoView({ behavior: "smooth" });
  }

  // "left, up"
  _navigateToPreviousElement(event) {
    if (!this.shouldNavigateToPrevious) return;

    this._navigateTo(this.previousElement, event, true);
  }

  // "right, down"
  _navigateToNextElement(event) {
    if (!this.shouldNavigateToNext) return;

    this._navigateTo(this.nextElement, event);
  }

  _navigateTo(element, event, moveToEnd = false) {
    event.preventDefault();
    event.stopPropagation();

    moveToEnd ? element.moveCursorToEnd() : element.focus();
    if (this.onNavigate) this.onNavigate();
  }

  get nextElement() {
    return this.allElements[this.currentIndex + 1];
  }

  get previousElement() {
    return this.allElements[this.currentIndex - 1];
  }

  get firstElement() {
    return this.allElements[0];
  }

  get lastElement() {
    const allElements = this.allElements;
    return allElements[allElements.length - 1];
  }

  get currentIndex() {
    return this.allElements.findIndex((el) => el.id === this.host.id);
  }

  get allElements() {
    return Array.from(document.querySelectorAll("[navigable]"));
  }

  get shouldNavigateToPrevious() {
    return (
      this.previousElement &&
      this.host.cursorAtStart &&
      !this.host.hasHighlightedText
    );
  }

  get shouldNavigateToNext() {
    return (
      this.nextElement && this.host.cursorAtEnd && !this.host.hasHighlightedText
    );
  }
}

export default NavigationController;
