import { Controller } from "stimulus";

export default class extends Controller {
  static values = { list: String, path: String, turboFrame: String };

  initialize() {
    this.hideOnClick = this._hideOnClick.bind(this);
    this.hideOnEscape = this._hideOnEscape.bind(this);
  }

  connect() {
    if (this.hasList) {
      document.addEventListener("click", this.hideOnClick);
      document.addEventListener("keydown", this.hideOnEscape);
    }
  }

  disconnect() {
    document.removeEventListener("click", this.hideOnClick);
    document.removeEventListener("keydown", this.hideOnEscape);
  }

  toggle() {
    const openChat = !this.hasList;
    const fullPath = `${this.pathValue}?open_chat=${openChat}`;
    Turbo.visit(fullPath, { frame: this.turboFrameValue });
  }

  _hideOnClick({ target }) {
    if (target === this.element) return;

    const id = "#" + this.element.id;
    if (target.closest(id)) return;

    this.toggle();
  }

  _hideOnEscape({ key, target }) {
    if (key !== "Escape") return;
    if (target.tagName === "TEXTAREA") return target.blur();

    this.toggle();
  }

  get hasList() {
    return this.list !== null;
  }

  get list() {
    return document.getElementById(this.listValue);
  }
}
