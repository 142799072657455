const CursorPositionMixin = (superClass) =>
  class extends superClass {
    // Public methods

    moveCursorToEnd() {
      if (this.isEmpty) return this.focus();

      const selection = this.currentSelection;
      const range = document.createRange();

      range.selectNodeContents(this.lastChild);
      range.setStart(range.endContainer, range.endOffset);

      selection.removeAllRanges();
      selection.addRange(range);
    }

    moveCursorToNextSibling() {
      const selection = this.currentSelection;
      const range = document.createRange();

      if (!this.nextSibling) return;

      range.selectNodeContents(this.nextSibling);
      range.setStart(range.endContainer, 1);
      range.setEnd(range.endContainer, 1);

      selection.removeAllRanges();
      selection.addRange(range);
    }

    saveRange() {
      this.savedRange = this.currentRange.cloneRange();
    }

    restoreSavedRange() {
      if (!this.savedRange) return;

      this.currentSelection.removeAllRanges();
      this.currentSelection.addRange(this.savedRange);
    }

    setCursorAt(charCount) {
      const sel = window.getSelection();

      const setCursor = (node, count) => {
        if (node.nodeType === Node.TEXT_NODE) {
          if (count <= node.length) {
            return { node, count }; // Return text node and offset
          } else {
            return { node: null, count: count - node.length };
          }
        } else {
          // Handle element nodes
          let accumulatedCount = count;
          for (let child of node.childNodes) {
            const result = setCursor(child, accumulatedCount);
            if (result.node) {
              return result; // Found node
            }
            accumulatedCount = result.count; // Update remaining offset
          }
          return { node: null, count: accumulatedCount };
        }
      };

      const result = setCursor(this, charCount);
      if (!result.node) {
        this.moveCursorToEnd();
        return;
      }

      const range = document.createRange();
      range.setStart(result.node, result.count);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }

    restoreCursorPosition(container, offset) {
      const range = document.createRange();

      // If the innerHTML of the container is entirely wrapped in a tag,
      // (e.g. `<content-element-input><b>foo</b></content-element-input>`)
      // the `offset` value doesn't work for setting the range.
      // In these cases (i.e. the `catch` block), we set the offset value to
      // the position of the wrapping tag, which is 1
      try {
        range.setEnd(container, offset);
        range.setStart(container, offset);
      } catch {
        range.setEnd(container, 1);
        range.setStart(container, 1);
      }

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }

    get contentBeforeCursor() {
      return this._contentRelativeToCursor("before");
    }

    get contentAfterCursor() {
      return this._contentRelativeToCursor("after");
    }

    get cursorAtStart() {
      return this._cursorAtPosition("start");
    }

    get cursorAtEnd() {
      return this._cursorAtPosition("end");
    }

    get isEmpty() {
      return this.innerText.trim() === "";
    }

    get isFocused() {
      return document.activeElement === this;
    }

    get hasHighlightedText() {
      return this.currentSelection.toString().length > 0;
    }

    get currentSelection() {
      return window.getSelection();
    }

    get currentRange() {
      if (this.currentSelection.rangeCount > 0)
        return this.currentSelection.getRangeAt(0);
    }

    get rangeAtEnd() {
      // Select the final text node
      // This ensures a node of type `#comment` isn't selected
      const endContainer = this._finalTextNode || this;
      const endOffset = endContainer?.textContent?.length || 0;

      return { endContainer, endOffset };
    }

    get cursorPosition() {
      let charCount = -1;
      const selection = window.getSelection();

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(this);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        charCount = preCaretRange.toString().length;
      }

      return charCount;
    }

    // Private methods

    _cursorAtPosition(position) {
      if (this.currentSelection.rangeCount === 0) return;

      const comparisonRange = document.createRange();
      comparisonRange.selectNodeContents(this);

      const rangeMethod = position === "start" ? "setEnd" : "setStart";
      comparisonRange[rangeMethod](
        this.currentRange.startContainer,
        this.currentRange.startOffset
      );

      return comparisonRange.toString().replaceAll(/ |<!---->/g, "") === "";
    }

    _contentRelativeToCursor(direction) {
      const comparisonRange = document.createRange();
      comparisonRange.selectNodeContents(this);

      const rangeMethod = direction === "before" ? "setEnd" : "setStart";
      // Ensure that selected content is removed
      const rangePosition = direction === "before" ? "start" : "end";

      // Set start/end on comparison to isolate content
      comparisonRange[rangeMethod](
        this.currentRange[`${rangePosition}Container`],
        this.currentRange[`${rangePosition}Offset`]
      );

      return this._getFragmentHTML(comparisonRange.cloneContents()).replaceAll(
        /<br>|<!---->/g,
        ""
      );
    }

    _getFragmentHTML(fragment) {
      return [...fragment.childNodes]
        .map((node) =>
          node.nodeName === "#text" ? node.textContent : node.outerHTML
        )
        .join("");
    }

    get _finalTextNode() {
      return Array.from(this.childNodes)
        .reverse()
        .find((node) => node.nodeName === "#text");
    }
  };

export default CursorPositionMixin;
