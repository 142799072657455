import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "definitionInput",
    "glossaryItemInput",
    "nameInput",
    "reportFilterInput",
    "turboFrame",
  ];

  static values = { filterPath: String };

  initialize() {
    this.frameLoadHandler = this._handleFrameLoad.bind(this);

    this.activeClasses = [
      "!bg-emerald-50",
      "!hover:bg-emerald-50",
      "!focus:bg-emerald-50",
    ];
  }

  connect() {
    this.activeItem = null;

    this.turboFrameTarget.addEventListener(
      "turbo:frame-render",
      this.frameLoadHandler
    );
  }

  disconnect() {
    this.turboFrameTarget.removeEventListener(
      "turbo:frame-render",
      this.frameLoadHandler
    );
  }

  select({ currentTarget }) {
    if (this.activeItem == currentTarget) {
      this._clearGlossaryInput();
    } else {
      this._setGlossaryInput(currentTarget);
    }
  }

  filter() {
    const param = `report_filter=${this.reportFilterInputTarget.dataset.value}`;
    this.searchController.search(param);
  }

  search() {
    this.filter();
  }

  _clearGlossaryInput() {
    this.activeItem.classList.remove(...this.activeClasses);
    this.activeItem = null;

    this.nameInputTarget.value = null;
    this.definitionInputTarget.value = null;
    this.glossaryItemInputTarget.value = null;
  }

  _setGlossaryInput(newTarget) {
    this.activeItem?.classList.remove(...this.activeClasses);

    this.activeItem = newTarget;
    newTarget.classList.add(...this.activeClasses);

    const { glossaryItem, name, definition } = newTarget.dataset;
    this.nameInputTarget.value = name;
    this.definitionInputTarget.value = definition;
    this.glossaryItemInputTarget.value = glossaryItem;
  }

  _handleFrameLoad() {
    if (!this.glossaryItemInputTarget.value) return;

    const selector = `[data-glossary-item="${this.glossaryItemInputTarget.value}"]`;
    const newTarget = this.element.querySelector(selector);
    this._setGlossaryInput(newTarget);
  }

  get searchController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("search_wrapper"),
      "shared--search--component"
    );
  }
}
