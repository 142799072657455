import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];
  static values = { highlights: Object };

  setHighlight({ params }) {
    const color = this.highlightsValue[params.key];
    const container = this.container;
    container.classList.remove(...Object.values(this.highlightsValue));
    container.classList.add(color);
  }

  get container() {
    return this.targets.find("container");
  }
}
