import { Controller } from "stimulus";
import { FetchRequest } from "@rails/request.js";
import Sortable from "sortablejs";

export default class extends Controller {
  static values = {
    handle: { type: String, default: "true" },
    increment: { type: Number, default: 0 },
    positionField: { type: String, default: "order_position" },
    skipPersistence: { type: Boolean, default: false },
  };

  connect() {
    Sortable.create(this.element, this.options);
  }

  _moveLinkedItem(item, newIndex) {
    let linkedItem = item.getAttribute("data-sortable-linked-item");
    if (!linkedItem) return;

    linkedItem = document.getElementById(linkedItem);
    if (!linkedItem) return;

    const linkedItemParent = linkedItem.parentElement;
    if (!linkedItemParent) return;

    let isInserted = false;
    const children = linkedItemParent.children;
    linkedItemParent.removeChild(linkedItem);

    Array.from(children).forEach((child, index) => {
      if (index === newIndex) {
        linkedItemParent.insertBefore(linkedItem, child);
        isInserted = true;
      }
    });

    if (!isInserted) linkedItemParent.appendChild(linkedItem);
  }

  _persistOrder(item, newIndex) {
    if (this.skipPersistenceValue) return;

    const url = `/record_order/${item.dataset.gid}`;
    const field = item.dataset.sortableField || this.positionFieldValue;
    const value = newIndex + this.incrementValue;
    const body = { field, value };
    const request = new FetchRequest("PATCH", url, { body });
    request.perform();
  }

  get options() {
    return {
      animation: 150,
      delay: 100,
      delayOnTouchOnly: true,
      direction: "vertical",
      easing: "cubic-bezier(1, 0, 0, 1)",
      ghostClass: "opacity-25",
      handle: this.handle,
      onEnd: ({ item, newIndex }) => {
        this._moveLinkedItem(item, newIndex);
        this._persistOrder(item, newIndex);
      },
    };
  }

  get handle() {
    return `[data-sortable-handle='${this.handleValue}']`;
  }
}
