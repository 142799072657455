import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    expandId: String,
    turboFrameId: String,
  };

  expand(event) {
    // Check if the trigger is in a collapsed state
    if (this.expandTrigger.dataset.collapseIsCollapsedValue === "false") return;

    event.preventDefault();
    const href = this.element.href;

    // First, we expand the section
    this.collapseButton.click();

    // If frame element has completed loading, we can already follow the link
    if (this.turboFrame.hasAttribute("complete")) {
      return (window.location.href = href);
    }

    // If frame element is *currently* loading, we can follow the link after it completes
    if (this.turboFrame.hasAttribute("busy")) {
      return this.turboFrame.addEventListener(
        "turbo:frame-load",
        () => (window.location.href = href)
      );
    }

    // If frame element has not started loading, we need to start it
    // Set the event listener to check if the frame has completed loading
    this.turboFrame.addEventListener("turbo:frame-load", () => {
      window.location.href = href;
    });

    // Start the loading process:
    this.turboFrame.loading = null;
    this.turboFrame.reload();
  }

  get expandTrigger() {
    return document.getElementById(this.expandIdValue);
  }

  get turboFrame() {
    return document.getElementById(this.turboFrameIdValue);
  }

  get collapseButton() {
    return this.expandTrigger.querySelector("[data-expand-collapse]");
  }
}
