import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // Dropzone is the area the user hovers over to upload a file
  // Label is the area the user clicks to upload a file (and where we display activeClasses)
  static targets = ["input", "text", "unselect", "dropzone", "label"];
  static values = { displayLoadState: Boolean };

  initialize() {
    this.handleDragleave = this._handleDragleave.bind(this);
    this.handleDragover = this._handleDragover.bind(this);
    this.handleDrop = this._handleDrop.bind(this);
    this.displayLoadState = this._displayLoadState.bind(this);
    this.label = this.hasLabelTarget ? this.labelTarget : this.dropzoneTarget;

    this.activeClasses = [
      "!bg-slate-50",
      "!border",
      "!border-slate-800",
      "!border-dashed",
    ];
  }

  connect() {
    if (this.hasDropzoneTarget) {
      const dropzone = this.dropzoneTarget;
      dropzone.addEventListener("dragleave", this.handleDragleave);
      dropzone.addEventListener("dragover", this.handleDragover);
      dropzone.addEventListener("drop", this.handleDrop);
    }

    if (this.displayLoadStateValue) {
      this.element.addEventListener("submit", this.displayLoadState);
    }
  }

  disconnect() {
    if (this.hasDropzoneTarget) {
      const dropzone = this.dropzoneTarget;
      dropzone.removeEventListener("dragleave", this.handleDragleave);
      dropzone.removeEventListener("dragover", this.handleDragover);
      dropzone.removeEventListener("drop", this.handleDrop);
    }

    if (this.displayLoadStateValue) {
      this.element.removeEventListener("submit", this.displayLoadState);
    }
  }

  async processFile() {
    const file = this.file;
    if (!file) return;

    this.textTarget.innerText = file.name.match(/([^\\]+$)/)[1];
    this.unselectTarget.classList.remove("hidden");
  }

  unselect() {
    this.inputTarget.value = "";
    this.unselectTarget.classList.add("hidden");
    this.textTarget.innerText = "Upload Image";
    this._triggerChange();
  }

  _handleDragleave() {
    this.label.classList.remove(...this.activeClasses);
  }

  _handleDragover(event) {
    event.preventDefault();
    this.label.classList.add(...this.activeClasses);
  }

  _handleDrop(event) {
    event.preventDefault();
    this.label.classList.remove(...this.activeClasses);
    this.inputTarget.files = event.dataTransfer.files;
    this.processFile();
    this._triggerChange();
  }

  _displayLoadState() {
    this.unselectTarget.innerText =
      "Uploading file. This may take a minute or two...";
  }

  _triggerChange() {
    if (this._isTriggering) return;

    this._isTriggering = true;
    this.inputTarget.dispatchEvent(new Event("change"));
    this._isTriggering = false;
  }

  get file() {
    return this.inputTarget.files[0];
  }
}
