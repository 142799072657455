import { Controller } from "stimulus";
import Animation from "../../../javascript/helpers/animation_helper";

export default class extends Controller {
  static targets = ["content"];
  static values = { hasContent: Boolean, notificationType: String };

  initialize() {
    this.escapeHandler = this.removeOnEscape.bind(this);
  }

  connect() {
    if (!this.hasContentValue) return;

    this.animation = new Animation();
    this.show();
  }

  async show() {
    if (!this.hasContentTarget) return;

    this.element.classList.remove("hidden");
    this.element.classList.add("flex");
    this.animation.enter(this.contentTarget);

    document.addEventListener("keydown", this.escapeHandler);

    // Don't autoclose loading notifications
    if (this.isLoading) return;

    // Autoclose notice and alert notifications
    await new Promise((resolve) => setTimeout(resolve, this.timeoutValue));
    this.hasContentTarget && this.hide();
  }

  async hide() {
    if (!this.hasContentTarget) return;

    await this.animation.exit(this.contentTarget);
    if (this.hasContentTarget) this.contentTarget.remove();
    document.removeEventListener("keydown", this.escapeHandler);
  }

  removeOnEscape({ key }) {
    if (key === "Escape") this.hide();
  }

  get timeoutValue() {
    return this.isNotice ? 6000 : 12000;
  }

  get isLoading() {
    return this.notificationTypeValue === "loading";
  }

  get isNotice() {
    return this.notificationTypeValue === "notice";
  }
}
