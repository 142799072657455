import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["anchor"];

  connect() {
    this._setInitialValues();
    this._spyOnScroll();
    this._scrollToActiveAnchor();
  }

  disconnect() {
    this._stopSpyingOnScroll();
  }

  _setInitialValues() {
    this.offset = 90;
    this.activeClasses = ["font-bold", "underline"];
    this.scrollContainer = document.querySelector("main");
  }

  _spyOnScroll() {
    this._checkCurrentVisibleSection();

    this.scrollContainer.addEventListener(
      "scroll",
      this._checkCurrentVisibleSection.bind(this)
    );
  }

  _stopSpyingOnScroll() {
    this.scrollContainer.removeEventListener(
      "scroll",
      this._checkCurrentVisibleSection
    );
  }

  _checkCurrentVisibleSection() {
    const currentVisibleSection = this.sections.find(
      this._isSectionVisible.bind(this)
    );

    if (!currentVisibleSection) return;

    const currentVisibleAnchor = this._getAnchorForSection(
      currentVisibleSection
    );

    if (currentVisibleAnchor !== this.activeAnchor) {
      this._setActiveAnchor(currentVisibleAnchor);
    }
  }

  _setActiveAnchor(anchor) {
    if (this.activeAnchor) this._removeActiveClasses();
    this.activeAnchor = anchor;
    this._addActiveClasses();
  }

  _isSectionVisible(element) {
    const rect = element.getBoundingClientRect();

    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );

    return !(rect.bottom < this.offset || rect.top - viewHeight >= this.offset);
  }

  _getAnchorForSection(section) {
    return this.anchorTargets.find(
      (anchor) => section.id === anchor.hash.slice(1)
    );
  }

  _removeActiveClasses() {
    let element = this.activeAnchor.querySelector("[scrollspy-style-target]");
    if (!element) element = this.activeAnchor;

    element.classList.remove(...this.activeClasses);
  }

  _addActiveClasses() {
    let element = this.activeAnchor.querySelector("[scrollspy-style-target]");
    if (!element) element = this.activeAnchor;

    element.classList.add(...this.activeClasses);
  }

  _scrollToActiveAnchor() {
    this.activeAnchor.scrollIntoView(false);
  }

  get sections() {
    return this.anchorTargets.map((anchor) =>
      document.querySelector(anchor.hash)
    );
  }
}
