import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "deNameInput",
    "deTextInput",
    "kind",
    "nameInput",
    "textInput",
    "turboFrame",
  ];

  initialize() {
    this.activeClasses = [
      "!bg-emerald-50",
      "!focus:bg-emerald-50",
      "!hover:bg-emerald-50",
    ];
  }

  connect() {
    this.activeItem = null;

    this.turboFrameTarget.addEventListener(
      "turbo:frame-render",
      this.frameLoadHandler
    );
  }

  disconnect() {
    this.turboFrameTarget.removeEventListener(
      "turbo:frame-render",
      this.frameLoadHandler
    );
  }

  select({ currentTarget }) {
    if (this.activeItem == currentTarget) {
      this._clearBaseQuestionInput();
    } else {
      this._setBaseQuestionInput(currentTarget);
    }
  }

  _clearBaseQuestionInput() {
    this.activeItem.classList.remove(...this.activeClasses);
    this.activeItem = null;

    this.nameInputTarget.value = null;
    this.textInputTarget.value = null;
    this._clearBaseQuestionKind();
  }

  _setBaseQuestionInput(newTarget) {
    this.activeItem?.classList.remove(...this.activeClasses);

    this.activeItem = newTarget;
    newTarget.classList.add(...this.activeClasses);

    const { name, text, deName, deText, kind } = newTarget.dataset;
    this.nameInputTarget.value = name;
    this.textInputTarget.value = text;
    this.deNameInputTarget.value = deName;
    this.deTextInputTarget.value = deText;
    this._setBaseQuestionKind(kind);
  }

  _clearBaseQuestionKind() {
    const firstRadio = this.kindTargets[0];
    firstRadio.checked = true;
  }

  _setBaseQuestionKind(kind) {
    const radio = this.kindTargets.find((radio) => radio.value === kind);
    radio.checked = true;
  }
}
