import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["contenteditable", "hiddenField"];
  static values = { allowHTML: Boolean };

  connect() {
    this.element.addEventListener("submit", this._setHiddenField.bind(this));
    this.element.addEventListener("paste", this._handlePaste.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("submit", this._setHiddenField.bind(this));
    this.element.removeEventListener("paste", this._handlePaste.bind(this));
  }

  _setHiddenField() {
    const content = this.allowHTMLValue
      ? this.contenteditableTarget.innerHTML.replaceAll(
          /<!---->|<mark>|<\/mark>/g,
          ""
        )
      : this.contenteditableTarget.innerText;

    this.hiddenFieldTarget.value = content;
  }

  _handlePaste(event) {
    event.preventDefault();
    const pastedText = (event.clipboardData || window.clipboardData).getData(
      "text/plain"
    );
    document.execCommand("insertText", false, pastedText);
  }
}
