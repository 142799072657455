import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["contenteditable", "textarea"];

  submit() {
    this.textareaTarget.value = this.contenteditableTarget.innerHTML;
    this.element.requestSubmit();
  }

  preventLinebreak(event) {
    if (event.key === "Enter") event.preventDefault();
  }
}
