import { Controller } from "stimulus";

export default class extends Controller {
  static values = { target: String, container: String };

  connect() {
    this.target = this._findTarget();
    this.container = this._findContainer();
    if (!this.target) return;

    requestAnimationFrame(() => this.scrollToBottom());
    this.element.remove();
  }

  async scrollToBottom() {
    let scrollHeight = 0;
    let attempts = 0;
    const maxAttempts = 10;

    while (scrollHeight === 0 && attempts < maxAttempts) {
      scrollHeight = this.container.scrollHeight;
      attempts++;
      await new Promise((resolve) => setTimeout(resolve, 20));
    }

    this.container.scrollTop = scrollHeight;
  }

  _findTarget() {
    if (!this.hasTargetValue) return this.element;
    return document.getElementById(this.targetValue);
  }

  _findContainer() {
    if (!this.hasContainerValue) return document.body;
    return document.getElementById(this.containerValue);
  }
}
