import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "submitButton"];
  static values = { requiredInput: String };

  toggleDisabled() {
    this.submitButtonTarget.disabled = !this.isRequiredInput;
  }

  get isRequiredInput() {
    return this.inputTarget.value === this.requiredInputValue;
  }
}
