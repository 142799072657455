import { Controller } from "stimulus";
import Animation from "../../../javascript/helpers/animation_helper";

export default class extends Controller {
  static targets = ["switch"];
  static values = { frameSelector: String, frameSrc: String, linkId: String };

  connect() {
    this.animation = new Animation();
  }

  toggle() {
    if (this.isOn) {
      this.switchOff();
    } else {
      this.switchOn();
    }
  }

  switchOn() {
    this.switchTarget.dataset.value = true;
    this.animation.enter(this.element, false);
    this.animation.enter(this.switchTarget, false);
  }

  switchOff() {
    this.switchTarget.dataset.value = false;
    this.animation.exit(this.element, false);
    this.animation.exit(this.switchTarget, false);
  }

  afterToggle() {
    // Reload turboFrame, if it exists
    if (this.hasFrameSelectorValue) {
      const turboFrame = document.getElementById(this.frameSelectorValue);
      turboFrame.src = this.frameSrcValue;
    }

    // Follow link, if it exists
    if (this.hasLinkIdValue) {
      const link = document.getElementById(this.linkIdValue);
      link.click();
    }
  }

  get isOn() {
    return this.switchTarget.dataset.value === "true";
  }
}
