import { Controller } from "stimulus";

export default class extends Controller {
  static values = { target: String, event: String };

  initialize() {
    this.loadFrame = this._loadFrame.bind(this);
    this.once = { once: true };
    this.target = document.getElementById(this.targetValue);
  }

  connect() {
    this.target.addEventListener(this.eventValue, this.loadFrame, this.once);
  }

  async _loadFrame() {
    this.element.removeAttribute("loading");
  }
}
