import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sidebar", "resizer"];
  static values = {
    minWidth: { type: Number, default: 150 },
    maxWidth: { type: Number, default: 500 },
    invert: { type: Boolean, default: false },
  };

  initialize() {
    this.mouseDown = this._handleMouseDown.bind(this);
    this.mouseMove = this._handleMouseMove.bind(this);
    this.mouseUp = this._handleMouseUp.bind(this);
  }

  connect() {
    this.initialMouseX = 0;
    this.initialSidebarWidth = 0;
    this.resizerTarget.addEventListener("mousedown", this.mouseDown);
  }

  disconnect() {
    this.resizerTarget.removeEventListener("mousedown", this.mouseDown);
    document.removeEventListener("mouseup", this.mouseUp);
    document.removeEventListener("mousemove", this.mouseMove);
  }

  _handleMouseDown(event) {
    // Adjust duration for smooth resizing
    this.sidebarTarget.classList.remove("duration-300");
    this.sidebarTarget.classList.add("duration-0");

    this.initialMouseX = event.clientX;
    const width = window.getComputedStyle(this.sidebarTarget).width;
    this.initialSidebarWidth = parseInt(width, 10);

    // Disable text selection
    document.body.classList.add("select-none");

    document.addEventListener("mousemove", this.mouseMove);
    document.addEventListener("mouseup", this.mouseUp);
  }

  _handleMouseMove(event) {
    const mouseMoveDistanceX = this._calculateDistanceX(event.clientX);
    const newSidebarWidth = this.initialSidebarWidth + mouseMoveDistanceX;

    if (newSidebarWidth > this.maxWidthValue) return;
    if (newSidebarWidth < this.minWidthValue) return;

    this.sidebarTarget.style.width = `${newSidebarWidth}px`;
  }

  _handleMouseUp() {
    // Reset duration value
    this.sidebarTarget.classList.remove("duration-0");
    this.sidebarTarget.classList.add("duration-300");

    // Enable text selection
    document.body.classList.remove("select-none");

    document.removeEventListener("mouseup", this.mouseUp);
    document.removeEventListener("mousemove", this.mouseMove);
  }

  _calculateDistanceX(currentMouseX) {
    if (this.invertValue) {
      return this.initialMouseX - currentMouseX;
    }

    return currentMouseX - this.initialMouseX;
  }
}
