export default class {
  constructor() {}

  beforeEnter(element, unhideElement) {
    if (!element) return;

    this.initTransitions(element);

    if (unhideElement) element.classList.remove("hidden");
  }

  async asyncEnter(element, unhideElement = true) {
    this.beforeEnter(element, unhideElement);

    await this.performAnimation(
      element,
      this.transitionEnter,
      this.transitionOff,
      this.transitionOn
    );
  }

  enter(element, unhideElement = true) {
    this.beforeEnter(element, unhideElement);

    this.performAnimation(
      element,
      this.transitionEnter,
      this.transitionOff,
      this.transitionOn
    );
  }

  beforeExit(element) {
    this.initTransitions(element);
  }

  afterExit(hideElement, element) {
    if (hideElement) element.classList.add("hidden");
  }

  async asyncExit(element, hideElement = true) {
    this.beforeExit(element);

    await this.performAnimation(
      element,
      this.transitionExit,
      this.transitionOn,
      this.transitionOff
    );

    this.afterExit(hideElement, element);
  }

  exit(element, hideElement = true) {
    this.beforeExit(element);

    this.performAnimation(
      element,
      this.transitionExit,
      this.transitionOn,
      this.transitionOff
    );

    this.afterExit(hideElement, element);
  }

  async performAnimation(element, transition, transitionFrom, transitionTo) {
    if (!element) return;

    if (transition) element.classList.add(...transition);
    await this.nextFrame();

    element.classList.remove(...transitionFrom);
    element.classList.add(...transitionTo);
    await this.transitionDuration(element);

    if (transition) element.classList.remove(...transition);
  }

  initTransitions({ dataset }) {
    this.transitionEnter = dataset.transitionEnter?.split(" ");
    this.transitionExit = dataset.transitionExit?.split(" ");
    this.transitionOn = dataset.transitionOn.split(" ");
    this.transitionOff = dataset.transitionOff.split(" ");
  }

  nextFrame() {
    return new Promise((resolve) => {
      requestAnimationFrame(() => {
        requestAnimationFrame(resolve);
      });
    });
  }

  transitionDuration(element) {
    return new Promise((resolve) => {
      const computedDuration =
        getComputedStyle(element).transitionDuration.split(",")[0];
      const duration = Number(computedDuration.replace("s", "")) * 1000;
      setTimeout(() => {
        resolve();
      }, duration);
    });
  }
}
