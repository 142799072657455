import { Controller } from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["input"];
  static values = { enableTime: Boolean };

  initialize() {
    this.attach = this._attach.bind(this);
    this.handleEscape = this._handleEscape.bind(this);
  }

  connect() {
    this._attach();
    this.element.addEventListener("flatpickr:resetForm", this.attach);
  }

  disconnect() {
    this.element.removeEventListener("flatpickr:resetForm", this.attach);
  }

  _attach() {
    this.inputTargets.forEach((input) => {
      flatpickr(input, {
        weekNumbers: true,
        altInput: true,
        enableTime: this.enableTimeValue,
        altFormat: this.altFormat,
        defaultHour: 18,
        defaultMinute: 0,
        onOpen: (_dates, _dateStr, instance) => this._onOpen(instance),
        onClose: async (_dates, _dateStr, instance) => this._onClose(instance),
      });
    });
  }

  _onOpen(instance) {
    this.instance = instance;
    const input = instance._input;
    const container = instance.calendarContainer;

    input.addEventListener("keydown", this.handleEscape);
    container.addEventListener("keydown", this.handleEscape);
  }

  async _onClose(instance) {
    await new Promise((resolve) => setTimeout(resolve, 1));

    const input = instance._input;
    const container = instance.calendarContainer;

    input.removeEventListener("keydown", this.handleEscape);
    container.removeEventListener("keydown", this.handleEscape);
  }

  _handleEscape(event) {
    if (event.key !== "Escape") return;

    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();

    this.instance.clear();
    this.instance = null;
  }

  get altFormat() {
    return this.enableTimeValue ? "d.m.y | H:i" : "d.m.y";
  }
}
