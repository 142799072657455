// Completes content element content via manual user request (cmd + control + n)
// Calls OpenAI GPT4 API to generate completion
import { Controller } from "stimulus";
import consumer from "../../channels/consumer";

export default class extends Controller {
  static values = { id: String };
  static targets = ["input"];

  initialize() {
    this.handleKeydown = this._handleKeydown.bind(this);
    this.handleBlur = this._handleBlur.bind(this);
    this.input = this.inputTarget;
    this.isRunning = false;

    const variant = "variant=complete";
    const path = `/content_elements/${this.idValue}/completions/new`;
    this.url = `${path}?${variant}`;
  }

  connect() {
    this.input.addEventListener("blur", this.handleBlur);
    this.input.addEventListener("keydown", this.handleKeydown);
  }

  disconnect() {
    this.input.removeEventListener("blur", this.handleBlur);
    this.input.removeEventListener("keydown", this.handleKeydown);
  }

  complete() {
    // Ensure cursor is at the end
    this.input.moveCursorToEnd();

    // Set up the stream
    this.subscription = this._createSubscription();

    // Reset suggestion
    this.input.dataset.suggestion = "loading...";

    // Fetch response
    this.isRunning = true;
    fetch(this.url);
  }

  _handleBlur() {
    if (!this.hasSuggestion) return;
    this._removeSuggestion();
  }

  _handleKeydown(event) {
    if (!this.hasSuggestion) return;

    // Ignore shift + meta keys
    if (event.key == "Shift") return;
    if (event.key == "Meta") return;

    // Accept the suggestion on tab
    if (event.key === "Tab") {
      event.preventDefault();
      if (!this.isRunning) this._acceptSuggestion();
      return;
    }

    // Remove it with any other key
    this._removeSuggestion();
  }

  _acceptSuggestion() {
    // Remove suggestion attribute
    const suggestion = this.input.dataset.suggestion;
    delete this.input.dataset.suggestion;

    // Add suggestion to input
    this.input.innerHTML += suggestion;
    this.input.moveCursorToEnd();

    // Ensure content is saved
    this.input.save();
  }

  _removeSuggestion() {
    delete this.input.dataset.suggestion;
    this.isRunning = false;
  }

  _createSubscription() {
    const options = {
      channel: "CompletionChannel",
      stream_key: this.streamKey,
    };

    consumer.subscriptions.create(options, {
      received: ({ suggestion, finished }) => {
        if (!this.isRunning) return;

        // Update suggestion
        if (suggestion) {
          this.input.dataset.suggestion = suggestion;
        }

        // Complete stream
        if (finished) this.isRunning = false;
      },
    });
  }

  get hasSuggestion() {
    const suggestion = this.input.dataset.suggestion;
    return suggestion && suggestion.length > 0;
  }

  get streamKey() {
    const userId = document.querySelector('meta[name="user-id"]').content;
    return `${this.idValue}_${userId}`;
  }
}
