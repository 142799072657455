import { Controller } from "stimulus";

export default class extends Controller {
  static values = { src: String, imageId: String, filename: String };

  connect() {
    this.element.addEventListener("click", this.download.bind(this));
  }

  async download(event) {
    event.stopPropagation();

    // Initialize variables
    const response = await fetch(this.src);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");

    // Set values on the anchor tag
    a.style.display = "none";
    a.href = url;
    a.download = this.filenameValue;

    // Append anchor and click
    document.body.appendChild(a);
    a.click();

    // Remove anchor and revoke URL
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  get src() {
    if (this.hasSrcValue) return this.srcValue;

    const image = document.getElementById(this.imageIdValue);
    return image.src;
  }
}
