import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["textarea"];
  static values = { saveTextarea: Boolean };

  submitOnEnter(event) {
    const { key, shiftKey, currentTarget } = event;
    if (key !== "Enter" || shiftKey) return;

    event.preventDefault();

    const inputValue = currentTarget.isContentEditable
      ? currentTarget.innerText
      : currentTarget.value;

    if (inputValue && inputValue.trim() === "") return;
    this.submit(event);
  }

  async submit(event) {
    if (this.saveTextareaValue) this.updateTextarea(event);
    await this.element.requestSubmit();

    event.currentTarget.focus();
  }

  updateTextarea({ currentTarget }) {
    this.textareaTarget.value = currentTarget.innerText;
  }

  clearOnSubmit({ detail: { success } }) {
    if (!success) return;

    this.element.reset();
    this.contenteditables.forEach((ce) => (ce.innerText = ""));
  }

  get contenteditables() {
    return this.element.querySelectorAll("[contenteditable]");
  }
}
