import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slider", "valueDisplay", "form", "input"];
  static values = { submitOnChange: { type: Boolean, default: true } };

  initialize() {
    this.handleInput = this._handleInput.bind(this);
    this.handleChange = this._handleChange.bind(this);
  }

  connect() {
    this.sliderTarget.addEventListener("input", this.handleInput);
    this.sliderTarget.addEventListener("change", this.handleChange);
  }

  disconnect() {
    this.sliderTarget.removeEventListener("input", this.handleInput);
    this.sliderTarget.removeEventListener("change", this.handleChange);
  }

  _handleInput() {
    this.valueDisplayTarget.innerText = this.sliderTarget.value;
  }

  _handleChange() {
    this.inputTarget.value = this.sliderTarget.value;

    if (this.submitOnChangeValue) this.formTarget.requestSubmit();
  }
}
