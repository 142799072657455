import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.hideOnEsc = this._hideOnEsc.bind(this);
    this.hideUnlessActive = this._hideUnlessActive.bind(this);
    this.hideOnClick = this._hideOnClick.bind(this);
    this.toggle = this._toggle.bind(this);
  }

  connect() {
    document.addEventListener("hideToolbars", this.hideUnlessActive);

    const contentElement = this.contentElement;
    contentElement.addEventListener("keyup", this.toggle);
    contentElement.addEventListener("mouseup", this.toggle);
    contentElement.addEventListener("touchend", this.toggle);
  }

  disconnect() {
    document.removeEventListener("hideToolbars", this.hideUnlessActive);

    const contentElement = this.contentElement;
    if (!contentElement) return;

    contentElement.removeEventListener("keyup", this.toggle);
    contentElement.removeEventListener("mouseup", this.toggle);
    contentElement.removeEventListener("touchend", this.toggle);
  }

  async toggleBold(event) {
    event.preventDefault();
    this.input.restoreSavedRange();
    await document.execCommand("bold", false, null);
    this._show();
  }

  async toggleItalic(event) {
    event.preventDefault();
    this.input.restoreSavedRange();
    await document.execCommand("italic", false, null);
    this._show();
  }

  async toggleUnderline(event) {
    event.preventDefault();
    this.input.restoreSavedRange();
    await document.execCommand("underline", false, null);
    this._show();
  }

  displayLinkModal(event) {
    event.preventDefault();
    this.contentElement.displayLinkModal();
  }

  displayFootnoteModal(event) {
    event.preventDefault();
    this.contentElement.displayFootnoteModal();
  }

  _toggle() {
    this.shouldHide ? this._hide() : this._show();
  }

  async _show() {
    // Hide existing toolbars
    const event = new CustomEvent("hideToolbars", { bubbles: true });
    await this.element.dispatchEvent(event);

    // Display + position toolbar
    this.element.classList.remove("hidden");
    this._setPosition();

    // Save range on input
    this.input.saveRange();

    // Hide on click
    setTimeout(() => {
      document.addEventListener("click", this.hideOnClick);
      document.addEventListener("keydown", this.hideOnEsc);
    }, 0);
  }

  _hide() {
    if (!this.isVisible) return;

    this.element.classList.add("hidden");
    document.removeEventListener("click", this.hideOnClick);
    document.removeEventListener("keydown", this.hideOnEsc);
  }

  _hideOnEsc({ key }) {
    if (key !== "Escape") return;
    this.forceHide = true;
    this._hide();
  }

  _hideOnClick({ target }) {
    if (target === this.element) return;
    if (target.closest("[data-controller='content-elements--toolbar']")) return;

    this._hide();
  }

  _hideUnlessActive({ target }) {
    if (target !== this.element) this._hide();
  }

  _setPosition() {
    const { x, y, width } = this.input.currentRange.getBoundingClientRect();
    const wrapperOffset = this.contentElement.getBoundingClientRect();

    let leftValue = x - wrapperOffset.x;
    const maxLeftValue = wrapperOffset.width - this.element.offsetWidth + 24;
    const minLeftValue = -70;
    const topValue = y - wrapperOffset.y;

    if (width) leftValue += Math.floor((width - this.element.offsetWidth) / 2);
    leftValue = Math.min(maxLeftValue, leftValue);

    if (leftValue < minLeftValue) {
      leftValue = minLeftValue;
    }

    this.element.style.left = leftValue + "px";
    this.element.style.top = topValue - 32 + "px";
  }

  get shouldHide() {
    const input = this.input;
    if (!input.isFocused) return true;
    if (!input.hasHighlightedText) return true;
    if (!input.allowFormatting) return true;

    if (this.forceHide) {
      this.forceHide = false;
      return true;
    }

    return false;
  }

  get contentElement() {
    return this.element.closest("content-element-block");
  }

  get input() {
    return this.contentElement.input;
  }

  get isVisible() {
    return !this.element.classList.contains("hidden");
  }
}
