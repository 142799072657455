// Controller that dispatches a custom event with the name passed as a value
// and then removes the element from the DOM.
import { Controller } from "stimulus";

export default class extends Controller {
  static values = { name: String };

  connect() {
    const customEvent = new CustomEvent(this.nameValue, { bubbles: true });
    this.element.dispatchEvent(customEvent);
    this.element.remove();
  }
}
