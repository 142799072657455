import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.characterCount = 6;
  }

  input({ target }) {
    if (target.value.length === this.characterCount) {
      this.element.submit();
    }
  }
}
