import { Controller } from "stimulus";
import Animation from "../helpers/animation_helper";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  initialize() {
    this.animation = new Animation();
    this.toggleHandler = this.toggle.bind(this);
  }

  connect() {
    if (!this.trigger) return;

    this.trigger.addEventListener("click", this.toggleHandler);
    this.setTriggerState();
  }

  disconnect() {
    if (!this.trigger) return;

    this.trigger.removeEventListener("click", this.toggleHandler);
  }

  setTriggerState() {
    if (this.isDisplayed) this.trigger.classList.add("bg-slate-200");
  }

  toggle() {
    this.element.style.removeProperty("width");

    this.animation[this.movement](this.element, false);
    this.trigger.classList.toggle("bg-slate-200");
    this.updateUserState();
  }

  updateUserState() {
    const body = { key: "show_sidebar", value: !this.isDisplayed };
    const request = new FetchRequest("PATCH", "/user_state", { body });
    request.perform();
  }

  get isDisplayed() {
    return this.element.classList.contains("translate-x-0");
  }

  get movement() {
    return this.isDisplayed ? "exit" : "enter";
  }

  get trigger() {
    return document.getElementById("sidebar_trigger");
  }
}
