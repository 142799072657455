import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    elementId: String,
    htmlContent: String,
    excludeUserId: String,
  };

  connect() {
    const element = document.getElementById(this.elementIdValue);
    if (element) this._broadcastChanges(element);
    this.element.remove();
  }

  _broadcastChanges(element) {
    const html = this.htmlContentValue;
    const excludeUserId = this.excludeUserIdValue;
    element.broadcastInputChange(html, excludeUserId);
  }
}
