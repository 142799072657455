import { Controller } from "stimulus";
import Animation from "../../../javascript/helpers/animation_helper";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.animation = new Animation();
  }

  toggle(event) {
    const { contentId, wrapperId } = event.currentTarget.dataset;
    const content = document.getElementById(contentId);
    this.wrapper = document.getElementById(wrapperId);

    if (content.classList.contains("hidden")) {
      event.stopPropagation();
      this.show(content);
    } else {
      this.hide(content);
    }
  }

  async show(content) {
    // Hide open dropdowns
    await this.contentTargets.forEach((target) => this.hide(target));

    // Show target dropdown
    await this.animation.asyncEnter(content);

    // Focus on first input
    const firstItem = content.querySelector(".dropdown-link");
    if (firstItem) firstItem.focus();

    this.wrapper.dispatchEvent(new CustomEvent("dropdown:shown"));

    // Add event listeners to dropdown
    this.hideOnClick(content);
    this.hideOnEsc(content);
  }

  async hide(content) {
    if (content.classList.contains("hidden")) return;

    await this.animation.asyncExit(content);
    this.wrapper.dispatchEvent(new CustomEvent("dropdown:hidden"));
  }

  hideOnClick(content) {
    document.addEventListener(
      "click",
      ({ target }) => {
        const isInput = target.tagName === "INPUT" && target.type !== "submit";

        if (this.preventHideOnClick(target) || isInput) {
          this.hideOnClick(content);
        } else {
          this.hide(content);
        }
      },
      { once: true }
    );
  }

  hideOnEsc(content) {
    document.addEventListener("keydown", ({ key }) => {
      if (key === "Escape") this.hide(content);
    });
  }

  preventHideOnClick(target) {
    if (target.dataset.preventHideOnClick) return true;

    const nestedValue = target.closest("[data-prevent-hide-on-click='true']");
    if (nestedValue) return true;

    return false;
  }
}
