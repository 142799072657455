// Ensure that the element is scrolled into view when the URL hash changes
// Without this logic, the element gets hidden behind the sticky header
import { Controller } from "stimulus";

export default class extends Controller {
  static values = { idKey: String };

  initialize() {
    this.cardOffset = 100;
    this.headingOffset = 50;
  }

  connect() {
    this.main = document.querySelector("main");
    this._scrollToElement({ onLoad: true });
    window.addEventListener("hashchange", this._scrollToElement.bind(this));
  }

  disconnect() {
    window.removeEventListener("hashchange", this._scrollToElement.bind(this));
  }

  async _scrollToElement({ onLoad = false }) {
    const id = window.location.hash;
    if (!id) return;

    const element = this.main.querySelector(id);
    if (!element) return;

    // Sleep for a moment on page load
    if (onLoad) await new Promise((resolve) => setTimeout(resolve, 1));

    this.main.scrollTop = this._getOffset(element);
  }

  _getOffset(element) {
    const isQuestion = element.id.includes(this.idKeyValue);
    const offset = isQuestion ? this.cardOffset : this.headingOffset;
    return element.offsetTop - offset;
  }
}
