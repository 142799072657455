// Controller for handling optimistic UI, where we apply classes to elements before the server responds.
// `this.actionsValue` is a map of element IDs to classes to apply to them.
// e.g. { "element-id": "hidden", "another-element-id": "font-bold text-green-100" }
import { Controller } from "stimulus";

export default class extends Controller {
  static values = { actions: Object };

  run({ params }) {
    if (this.disableRun(params)) return;

    Object.entries(this.actionsValue).forEach(([id, classes]) => {
      const target = document.getElementById(id);
      const classArray = classes.split(" ");
      classArray.forEach((className) => target.classList.toggle(className));
    });
  }

  disableRun({ confirm }) {
    if (!confirm) return false;

    return !window.confirm(confirm);
  }
}
