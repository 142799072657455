// This is the parant conductor of the collapse_controller
// By convention, the parent name is the plural of the child name
import Conductor from "../helpers/conductor_helper";

// create a kid controller by extending stimulus-conductor controller
export default class extends Conductor {
  static targets = ["expandTrigger", "collapseTrigger"];

  expandAll() {
    this.controllers.forEach((controller) => controller.showMore());

    this.expandTriggerTarget.classList.add("hidden");
    this.collapseTriggerTarget.classList.remove("hidden");
  }

  collapseAll() {
    this.controllers.forEach((controller) => controller.showLess());

    this.collapseTriggerTarget.classList.add("hidden");
    this.expandTriggerTarget.classList.remove("hidden");
  }

  _fetchController(element) {
    return this.application.getControllerForElementAndIdentifier(
      element,
      "task"
    );
  }

  get controllers() {
    return this.taskControllers || this.manuallyFetchControllers;
  }

  get manuallyFetchControllers() {
    const query = "[data-controller*='task']";
    const conductors = this.element.querySelectorAll(query);

    return Array.from(conductors).reduce((acc, conductor) => {
      const controller = this._fetchController(conductor);
      return controller ? [...acc, controller] : acc;
    }, []);
  }
}
