import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    text: String,
    inputId: String,
  };

  insertInputValue() {
    const inputField = document.getElementById(this.inputIdValue);
    inputField.value = this.textValue;
    inputField.focus();

    // Create a new input event
    const eventOptions = { bubbles: true, cancellable: true };
    const event = new Event("input", eventOptions);

    // Dispatch the event
    inputField.dispatchEvent(event);
  }
}
