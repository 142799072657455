import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["emptyState"];

  static values = {
    reloadOnConnect: Boolean,
    loadLazy: Boolean,
    turboFrameIds: Array,
    redirectOnFrameMissing: Boolean,
  };

  connect() {
    // Reload on connect
    if (this.hasReloadOnConnectValue && this.reloadOnConnectValue) {
      this.turboFrames.forEach((frame) => this._reloadFrame(frame));
    }

    // Lazy load frames
    if (this.hasLoadLazyValue) {
      const lazyFrameQuery = "turbo-frame[loading='lazy']";
      this.lazyFrames = this.element.querySelectorAll(lazyFrameQuery);
      this.finalLazyFrameIndex = this.lazyFrames.length - 1;
      this._loadLazyFrame(0);
    }

    // Redirect on frame-missing error
    if (this.hasRedirectOnFrameMissingValue) {
      this.element.addEventListener("turbo:frame-missing", (event) => {
        const response = event.detail.response;
        if (response.status !== 200) return;

        event.preventDefault();
        Turbo.visit(event.detail.response.url);
      });
    }
  }

  _reloadFrame(frame) {
    if (frame.src) {
      frame.reload();
    } else {
      frame.src = window.location;
    }
  }

  _loadLazyFrame(index) {
    if (index > this.finalLazyFrameIndex) {
      this._handleEmptyState();
      return;
    }

    // Get frame
    const frame = this.lazyFrames[index];

    // Set event listener for the next frame
    frame.addEventListener(
      "turbo:frame-load",
      () => this._loadLazyFrame(index + 1),
      { once: true }
    );

    // Load the current frame
    const src = frame.src || frame.getAttribute("async_src");
    frame.removeAttribute("loading");
    frame.src = null;
    frame.src = src;
  }

  _handleEmptyState() {
    if (!this.hasEmptyStateTarget) return;

    // Check whether all lazy frames are empty
    if (this.lazyFramesAreEmpty) {
      this.emptyStateTarget.classList.remove("hidden");
    }
  }

  get turboFrames() {
    return this.turboFrameIdsValue.map((id) => document.getElementById(id));
  }

  get lazyFramesAreEmpty() {
    return Array.from(this.lazyFrames).every((frame) => frame.innerText === "");
  }
}
