import { Controller } from "stimulus";

export default class extends Controller {
  static values = { elementId: String };

  initialize() {
    this.attempts = 0;
  }

  async connect() {
    if (this.focusOnElement()) {
      this.element.remove();
      return;
    }

    if (this.attempts > 10) {
      this.element.remove();
      return;
    }

    setTimeout(() => this.connect(), 100);
  }

  focusOnElement() {
    const element = document.getElementById(this.elementIdValue);

    if (!element) {
      this.attempts += 1;
      return false;
    }

    element.focus();
    return true;
  }
}
