import { Controller } from "stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static values = { loading: String };

  connect() {
    this.acceptedTags = ["B", "I", "U", "A"];
  }

  handlePaste(event) {
    event.preventDefault();

    const clipboardData = (event.originalEvent || event).clipboardData;
    const html = clipboardData.getData("text/html");
    const text = clipboardData.getData("text/plain");

    if (this._shouldSanitize(html)) {
      this._sanitize(html, text);
    } else {
      this._insertText(text);
    }
  }

  _shouldSanitize(html) {
    if (this.preventSplitting) return false;
    if (html.includes("\n")) return true;

    const doc = new DOMParser().parseFromString(html, "text/html");
    let tags = Array.from(doc.getElementsByTagName("*"));
    tags = tags.filter((tag) => this._hasInnerText(tag));

    if (tags.length < 2) return false;

    // Return true if any tag is not in the acceptedTags array
    return tags.some((tag) => !this.acceptedTags.includes(tag.tagName));
  }

  _hasInnerText(tag) {
    if (tag.childNodes.length === 0) {
      return tag.textContent.trim() !== "";
    }

    return (
      Array.from(tag.childNodes)
        .filter((node) => node.nodeType === Node.TEXT_NODE)
        .map((node) => node.textContent.trim())
        .join("")
        .trim() !== ""
    );
  }

  _insertText(text) {
    text = text.replace(/[\n\r\t]/gm, "");
    document.execCommand("insertText", false, text);
  }

  async _sanitize(html, text) {
    this._setLoadingState();

    const body = JSON.stringify({
      content: html,
      before_cursor: this.element.contentBeforeCursor,
      after_cursor: this.element.contentAfterCursor,
    });

    const request = new FetchRequest("post", this.path, { body: body });
    const responseRequest = await request.perform();
    const response = responseRequest.response;
    this._removeLoadingState();

    if (response.status !== 200) this._insertText(text);
  }

  _setLoadingState() {
    this.hasPlaceholder =
      this.element.getAttribute("data-permanent-placeholder") === "true";

    if (!this.hasPlaceholder) {
      this.element.setAttribute("data-permanent-placeholder", "true");
    }

    this.element.setAttribute("contenteditable", false);
    this.previousHTML = this.element.innerHTML;
    this.loadingContainer.innerHTML = this.loadingHTML;
  }

  _removeLoadingState() {
    if (!this.hasPlaceholder) {
      this.element.removeAttribute("data-permanent-placeholder");
      this.hasPlaceholder = null;
    }

    this.element.setAttribute("contenteditable", true);
    this.loadingContainer.innerHTML = this.previousHTML;
    this.previousHTML = null;
  }

  get path() {
    const recordId = this.element.id.split("_").reverse()[0];
    return `/content_elements/${recordId}/sanitizations`;
  }

  get preventSplitting() {
    return this.element.getAttribute("allowsplitting") !== "true";
  }

  get loadingContainer() {
    return document.getElementById(this.loadingValue);
  }

  get loadingHTML() {
    const classes = "text-slate-400 animate-pulse text-xs font-medium";
    return `<div class="${classes}">Formatting...</div>`;
  }
}
