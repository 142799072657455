import { Controller } from "stimulus";
import { isInput } from "../../../javascript/helpers/text_helper";

export default class extends Controller {
  static targets = ["closeIcon", "input", "inputContainer", "searchIcon"];

  static values = {
    path: String,
    turboFrameSelector: String,
    preventCollapsedState: Boolean,
  };

  initialize() {
    this.focusOnSlash = this._focusOnSlash.bind(this);
    this.clearSearchOnEsc = this._clearSearchOnEsc.bind(this);
  }

  connect() {
    document.addEventListener("keydown", this.focusOnSlash);

    if (this.hasInputTarget) {
      this.inputTarget.addEventListener("keydown", this.clearSearchOnEsc);
    }
  }

  disconnect() {
    document.removeEventListener("keydown", this.focusOnSlash);

    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener("keydown", this.clearSearchOnEsc);
    }
  }

  toggleDisplay() {
    if (this.hasSearchIconTarget) {
      this.searchIconTarget.classList.toggle("hidden");
    }

    if (this.hasInputContainerTarget) {
      this.inputContainerTarget.classList.toggle("hidden");

      if (!this.inputContainerTarget.classList.contains("hidden"))
        this.inputTarget.focus();
    }
  }

  clearSearch() {
    this.inputTarget.value = "";
    this.turboFrame.src = this.src;
    this.inputTarget.blur();
    this.toggleDisplay();
  }

  search(additionalParams = "") {
    // Ignore additionalParams unless a string
    if (typeof additionalParams !== "string") {
      return (this.turboFrame.src = this.src);
    }

    // Ignore additionalParams unless present
    if (additionalParams === "") {
      return (this.turboFrame.src = this.src);
    }

    // Add additionalParams to the src
    this.turboFrame.src = `${this.src}&${additionalParams}`;
  }

  _focusOnSlash(event) {
    if (event.key !== "/") return;
    if (isInput(event.target)) return;

    event.preventDefault();

    this.isHidden ? this.toggleDisplay() : this.inputTarget.focus();
  }

  _clearSearchOnEsc({ key }) {
    if (key === "Escape") this.clearSearch();
  }

  get isHidden() {
    return this.inputContainerTarget.classList.contains("hidden");
  }

  get searchValue() {
    return encodeURIComponent(this.inputTarget.value);
  }

  get turboFrame() {
    if (this._turboFrame) return this._turboFrame;

    this._turboFrame = document.querySelector(this.turboFrameSelectorValue);
    return this._turboFrame;
  }

  get src() {
    return this.pathValue.includes("?")
      ? `${this.pathValue}&search=${this.searchValue}`
      : `${this.pathValue}?search=${this.searchValue}`;
  }
}
