import { FetchRequest } from "@rails/request.js";

export default class ContentElementPersister {
  constructor(contentElement) {
    this.contentElement = contentElement;
    this.id = contentElement.dataset.contentElementId;
  }

  save() {
    const url = `/content_elements/${this.id}/saves`;
    const body = { content: this.input.content };
    const request = new FetchRequest("PATCH", url, { body });
    request.perform();
  }

  split() {
    const input = this.input;
    const url = `/content_elements/${this.id}/splits`;
    const beforeCursor = input.contentBeforeCursor;
    const afterCursor = input.contentAfterCursor;
    const body = { before_cursor: beforeCursor, after_cursor: afterCursor };
    this.input.blur();

    const request = new FetchRequest("POST", url, { body });
    request.perform();
  }

  mergePrevious() {
    const url = `/content_elements/${this.id}/merges`;
    const body = { direction: "previous", content: this.input.content };

    const request = new FetchRequest("PATCH", url, { body });
    request.perform();
  }

  mergeNext() {
    const url = `/content_elements/${this.id}/merges`;
    const body = { direction: "next", content: this.input.content };

    const request = new FetchRequest("PATCH", url, { body });
    request.perform();
  }

  indent() {
    const url = `/content_elements/${this.id}/indentations`;
    const body = { content: this.input.content, direction: "indent" };

    const request = new FetchRequest("PATCH", url, { body });
    request.perform();
  }

  unindent() {
    const url = `/content_elements/${this.id}/indentations`;
    const body = { content: this.input.content, direction: "unindent" };

    const request = new FetchRequest("PATCH", url, { body });
    request.perform();
  }

  destroy() {
    const url = `/content_elements/${this.id}/deletions`;

    const request = new FetchRequest("DELETE", url);
    request.perform();
  }

  get input() {
    return this.contentElement.input;
  }
}
