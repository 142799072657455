import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["header", "menuElement"];

  toggleSelected({ target }) {
    // Update menu header with selected tab
    this.headerTarget.innerText = target.innerText;

    // Reset all menu elements to default color
    this.menuElementTargets.forEach((element) =>
      element.classList.remove("text-green-accent")
    );

    // Set selected menu element to green
    target.classList.add("text-green-accent");
  }
}
