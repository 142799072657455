import { Controller } from "stimulus";
import consumer from "../../channels/consumer";

export default class extends Controller {
  static values = { page: String };

  initialize() {
    this.userId = document.querySelector("meta[name=user-id]").content;
  }

  connect() {
    this.channel = this._createChannel();
  }

  disconnect() {
    if (this.channel) {
      this.channel.unsubscribe();
      this.channel = null;
    }
  }

  _createChannel() {
    return consumer.subscriptions.create(
      {
        channel: "PresenceChannel",
        page: this.pageValue,
      },
      {
        received: (data) => this._replaceLists(JSON.parse(data)),
      }
    );
  }

  async _replaceLists(lists) {
    const replacedListIds = [];

    // Replace existing lists with the new lists
    await Promise.all(
      lists.map(async ({ id, list }) => {
        const listElement = await this._fetchList(id);

        if (listElement) {
          listElement.outerHTML = list;
          replacedListIds.push(id);
        }
      })
    );

    // Hide current user from navbar list
    if (this.navbarUser) this.navbarUser.style.display = "none";

    // Remove html for lists that no longer have users
    this.allLists.forEach((list) => {
      if (!replacedListIds.includes(list.id)) {
        list.innerHTML = "";
      }
    });
  }

  async _fetchList(id) {
    let list = document.getElementById(id);
    let attempts = 0;
    const maxAttempts = 20;

    while (!list && attempts < maxAttempts) {
      await new Promise((resolve) => setTimeout(resolve, 200));
      list = document.getElementById(id);
      attempts++;
    }

    return list;
  }

  get allLists() {
    return document.querySelectorAll("[presence_list]");
  }

  get navbarUser() {
    const navbarList = document.getElementById("presence_navbar");
    return navbarList.querySelector(`[data-user-id="${this.userId}"]`);
  }
}
