import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.removeItem = this._removeItem.bind(this);
    this.form = this.element.closest("form") || this.element;
  }

  connect() {
    this.element.value = localStorage.getItem(this.element.id);
    this.form.addEventListener("turbo:submit-end", this.removeItem);
  }

  disconnect() {
    this.element.removeEventListener("input", this.setItem);
    this.form.removeEventListener("turbo:submit-end", this.removeItem);
  }

  setItem() {
    localStorage.setItem(this.element.id, this.element.value);
  }

  _removeItem({ detail }) {
    if (detail.success) localStorage.removeItem(this.element.id);
  }
}
