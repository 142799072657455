export const moveCursorToEnd = (input) => {
  if (input.value) {
    input.focus();
    return input.setSelectionRange(input.value.length, input.value.length);
  }

  if (input.innerText.trim() === "") return input.focus();

  moveCursorToOffset(input, input.lastChild.length || 0);
};

export const moveCursorToOffset = (input, offset) => {
  const selection = window.getSelection();
  const range = document.createRange();

  if (!range || !input) return;

  if (!input.lastChild) {
    input.appendChild(document.createTextNode(""));
  }

  range.setStart(input.lastChild, offset);
  selection.removeAllRanges();
  selection.addRange(range);
};

export const isInput = (element) => {
  return (
    element.tagName === "TEXTAREA" ||
    element.type === "text" ||
    element.isContentEditable
  );
};

export const getInput = (el) => {
  if (isInput(el)) return el;
  return el.querySelector(
    "[type=text]:not(.hidden), textarea:not(.hidden), [contenteditable=true]:not(.hidden"
  );
};
