import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["body", "markDone", "showLess", "showMore"];

  showMore() {
    this.bodyTarget.classList.remove(this.maxHeightClass);
    this.showMoreTarget.classList.add("hidden");
    this.showLessTarget.classList.remove("hidden");
    this.showLessTarget.classList.add("flex");
  }

  showLess() {
    this.bodyTarget.classList.add(this.maxHeightClass);
    this.showMoreTarget.classList.remove("hidden");
    this.showLessTarget.classList.add("hidden");
    this.showLessTarget.classList.remove("flex");
  }

  async markDone() {
    this.markDoneTarget.classList.toggle("text-green-accent");
    this.element.classList.add("opacity-0", "scale-90");

    // Wait for the transition to complete before following the link
    await new Promise((resolve) => setTimeout(resolve, 400));
    this.element.remove();
  }

  get maxHeightClass() {
    return "max-h-[24rem]";
  }

  get taskListFrame() {
    return (this._taskListFrame ||= document.getElementById("tasks"));
  }
}
