import { Controller } from "stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static values = { path: String, attribute: String };

  initialize() {
    this.save = this._save.bind(this);
    this.paste = this._paste.bind(this);
  }

  connect() {
    this.element.classList.add(
      "focus:outline-none",
      "empty:text-slate-300",
      "empty:italic"
    );

    this.element.dataset.permanentPlaceholder = true;
    this.element.setAttribute("contenteditable", true);
    this._setListeners("add");

    if (this.element.dataset.autofocus === "true") this.element.focus();
  }

  disconnect() {
    this._setListeners("remove");
  }

  _save() {
    const body = this.body;
    const request = new FetchRequest("PATCH", this.pathValue, { body });
    request.perform();
  }

  _paste(event) {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    document.execCommand("insertText", false, text);
    this.save();
  }

  _setListeners(verb) {
    const action = `${verb}EventListener`;
    this.element[action]("debounced:input", this.save);
    this.element[action]("blur", this.save);
    this.element[action]("paste", this.paste);
  }

  get body() {
    return {
      attribute: this.attributeValue,
      value: this.element.textContent,
    };
  }
}
