export default class {
  constructor(dropzone, options) {
    this.dropzone = dropzone;
    this.input = options.input;
    this.form = options.form;
    this.onHover = options.onHover;
    this.onDrop = options.onDrop;
    this.dragLeave = options.dragLeave;
    this.allowUpload = options.allowUpload;
    this.unacceptedFileTypes = ["svg+xml", "webp"];

    this._setEventListeners();
  }

  disconnect() {
    this.dropzone.removeEventListener("dragleave", this._dragLeave);
    this.dropzone.removeEventListener("dragover", this._dragOver);
    this.dropzone.removeEventListener("drop", this._drop);
  }

  _setEventListeners() {
    this.dropzone.addEventListener("dragleave", this._dragLeave.bind(this));
    this.dropzone.addEventListener("dragover", this._dragOver.bind(this));
    this.dropzone.addEventListener("drop", this._drop.bind(this));
  }

  _dragLeave() {
    this.dragLeave();
  }

  _dragOver(event) {
    if (!this.allowUpload()) return;

    event.preventDefault();
    this.onHover();
  }

  _drop(event) {
    if (!this.allowUpload()) return;

    // Clean the files array, keep only images of the accepted types and weight
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const images = files.filter((file) => this.checkFile(file));
    if (images.length === 0) return;

    this._attachImagesToInput(images);
    this.onDrop(images);

    // Submit form via AJAX to prevent turbo progress bar from being displayed
    const formData = new FormData(this.form);
    const csrf = document.querySelector("meta[name=csrf-token]").content;

    fetch(this.form.action, {
      method: "POST",
      headers: { "X-CSRF-Token": csrf },
      body: formData,
    });
  }

  async checkFile(file) {
    if (!this._isImageFile(file)) return false;

    // Prevent uploads of unsupported file types (svgs, Webp)
    const fileType = file.type.split("/")[1];
    if (this.unacceptedFileTypes.includes(fileType)) return false;

    // Ensure image works out to less than 25 megapixels
    const megapixels = await this._getImageMegapixels(file);
    if (megapixels > 25) return false;
  }

  _isImageFile(file) {
    return file.type.match(/image\/(jpeg|jpg|png|gif|webp|svg\+xml)/);
  }

  _attachImagesToInput(images) {
    const dataTransfer = new DataTransfer();
    images.forEach((image) => dataTransfer.items.add(image));
    this.input.files = dataTransfer.files;
  }

  get csrf() {
    return document.querySelector("meta[name=csrf-token]").content;
  }

  _getImageMegapixels(file) {
    return new Promise((resolve) => {
      // Build empty image object
      const img = new Image();

      // Set onload callback on image
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const megapixels = (width * height) / 1_000_000;
        // Actually return the megapixels value
        resolve(megapixels);
      };

      // Add src to image
      img.src = URL.createObjectURL(file);
    });
  }
}
