// Sends PATCH request to `toggle_asked#update` when user toggles `is_asked`
import { Controller } from "stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static values = { path: String };

  run() {
    const request = new FetchRequest("patch", this.pathValue);
    request.perform();
  }
}
