import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { isSelected: Boolean, limitOne: Boolean, allTags: String };
  static classes = ["selected", "unselected"];
  static targets = ["checkbox", "selectable"];

  initialize() {
    this.selectables = this.hasSelectableTarget
      ? this.selectableTargets
      : [this.element];
  }

  toggleSelected() {
    this.isSelectedValue = !this.isSelectedValue;
    this.checkboxTarget.checked = this.isSelectedValue;

    if (this.limitOneValue) this._unselectOthers();
  }

  isSelectedValueChanged() {
    if (this.isSelectedValue) {
      this.selectables.forEach((selectable) => {
        selectable.classList.add(...this.selectedClasses);
        selectable.classList.remove(...this.unselectedClasses);
      });
    } else {
      this.selectables.forEach((selectable) => {
        selectable.classList.add(...this.unselectedClasses);
        selectable.classList.remove(...this.selectedClasses);
      });
    }
  }

  _unselectOthers() {
    this.otherTags.forEach((tag) => {
      const controller = this._fetchController(tag);
      controller.isSelectedValue = false;
    });
  }

  _fetchController(tag) {
    return this.application.getControllerForElementAndIdentifier(tag, "tag");
  }

  get otherTags() {
    const allTags = document.querySelectorAll(this.allTagsValue);
    return Array.from(allTags).filter((tag) => tag !== this.element);
  }
}
