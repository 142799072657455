import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fileInput", "pagesInput"];

  processFile() {
    const file = this.file;
    if (!file) return this.unselect();

    if (this.isPdfFile) {
      this.pagesInputTarget.classList.remove("hidden");
      this.inputField.focus();
    }
  }

  unselect() {
    this.pagesInputTarget.classList.add("hidden");
  }

  get isPdfFile() {
    return this.file.type === "application/pdf";
  }

  get file() {
    return this.fileInputTarget.files[0];
  }

  get inputField() {
    return this.pagesInputTarget.querySelector("input");
  }
}
