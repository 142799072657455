import { Controller } from "stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static values = { path: String };

  async connect() {
    const request = new FetchRequest("GET", this.pathValue);
    const responsePromise = await request.perform();
    const response = responsePromise.response;

    this._handleResponse(response);
    this.element.remove();
  }

  _handleResponse(response) {
    if (response.ok) {
      Turbo.renderStreamMessage(response);
    } else {
      console.error(response.statusText);
    }
  }
}
