import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "topicSelect",
    "questionRadios",
    "contentElement",
    "contentElementsInput",
  ];

  initialize() {
    this.activeClass = "!bg-emerald-50";
  }

  setChapter({ target }) {
    // Hide all topicSelects
    this.topicSelectTargets.forEach((topicSelect) =>
      topicSelect.classList.add("hidden")
    );

    // Find topicSelect for this chapter
    const chapterId = target.value;
    const topicSelect = this.topicSelectTargets.find(
      (topicSelect) => topicSelect.dataset.chapterId === chapterId
    );

    // Display that topicSelect
    topicSelect.classList.remove("hidden");
    const topicSelectInput = topicSelect.querySelector("select");

    // Handle topic selection
    this.setTopic({ target: topicSelectInput });
  }

  setTopic({ target }) {
    // Hide all questionRadios
    this.questionRadiosTargets.forEach((questionRadio) =>
      questionRadio.classList.add("hidden")
    );

    // Find questionRadio for this topic
    const topicId = target.value;
    const questionRadioList = this.questionRadiosTargets.find(
      (questionRadios) => questionRadios.dataset.topicId === topicId
    );

    // Display that questionRadio
    questionRadioList.classList.remove("hidden");

    // Get radios from list
    const radios = questionRadioList.querySelectorAll("input[type=radio]");

    // Check first radio if none are checked
    let checked = false;
    radios.forEach((radio) => {
      if (radio.checked) checked = true;
    });

    if (checked == false) radios[0].checked = true;
  }

  toggleSelection({ currentTarget }) {
    currentTarget.classList.toggle(this.activeClass);
    this._setElementIds();
  }

  _setElementIds() {
    const contentElementIds = [];

    // Get active content elements
    this.contentElementTargets.forEach((element) => {
      if (element.classList.contains(this.activeClass)) {
        contentElementIds.push(element.dataset.id);
      }
    });

    // Store value in hidden input
    this.contentElementsInputTarget.value = contentElementIds.join(",");
  }
}
