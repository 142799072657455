import { Controller } from "stimulus";

export default class extends Controller {
  submit() {
    this.element.requestSubmit();
  }

  // When the user blurs the last (i.e newest) item on the page,
  // ping the server to (potentially) reposition the records by date
  refreshOrder() {
    const allItems = this.allItems;
    const lastItem = allItems[allItems.length - 1];
    if (this.element !== lastItem) return;

    const action = this.element.action;
    this.element.action += "?reposition_records=true";
    this.submit();
    this.element.action = action;
  }

  handleKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.submit();
      document.activeElement.blur();
    }
  }

  get allItems() {
    return document.querySelectorAll(
      "[data-controller~='information-base-form']"
    );
  }
}
