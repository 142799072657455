import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "loading"];
  static values = { url: String };

  initialize() {
    this.handleKeydown = this._keydown.bind(this);
  }

  connect() {
    if (this.hasInputTarget) {
      this.inputTarget.addEventListener("keydown", this.handleKeydown);
    }
  }

  disconnect() {
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener("keydown", this.handleKeydown);
    }
  }

  // "cmd + control + s"
  _keydown(event) {
    if (!event.metaKey) return;
    if (!event.ctrlKey) return;
    if (event.key !== "s") return;

    // Prevent default
    event.preventDefault();

    // Run summarize logic
    this.summarize();
  }

  async summarize() {
    // Disable element
    this.inputTarget.setAttribute("contenteditable", false);

    // Send request to the summarize endpoint
    fetch(this.urlValue, this.options);
  }

  get csrf() {
    return document.querySelector("meta[name=csrf-token]").content;
  }

  get options() {
    return { method: "GET", headers: { "X-CSRF-Token": this.csrf } };
  }
}
